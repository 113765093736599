import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

const { Menu: { ContextMenu } } = require('react-data-grid-addons');

class ReportsContextMenu extends PureComponent {
  render() {
    const { id, rowIdx } = this.props;
    const patientID = this.props.reports[rowIdx] ? this.props.reports[rowIdx] : 0;
    return (
      <ContextMenu id={id} className="contextMenu">
        <Link to={{ pathname: `/patient/${patientID._id}`, state: { patientInfo: this.props.reports[rowIdx] } }}><div className="menu--item">View Patient</div></Link>
        <Link to={{ pathname: `/editPatient/${patientID._id}`, state: { patientInfo: this.props.reports[rowIdx] } }}><div className="menu--item">Edit Patient</div></Link>
      </ContextMenu>
    );
  }
}

export default ReportsContextMenu;
