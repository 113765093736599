export const searchActions = {
  SET_TEXT_FILTER: 'SET_TEXT_FILTER',

  setTextFilter: text => ({
    type: searchActions.SET_TEXT_FILTER,
    payload: {
      text,
    },
  }),
};
