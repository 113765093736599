import { notificationActions } from './actions';

const notificationDefaultState = {
  notification: [],
};

export function notificationReducer(state = notificationDefaultState, { payload, type }) {
  switch (type) {
    case notificationActions.SET_NOTIFICATIONS:
      return {
        ...state,
        notification: payload.notificationData,
      };
    default:
      return state;
  }
}
