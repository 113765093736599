export function loadReportsSelector(patients, textFilter) {
  return patients.filter((patient) => {
    const textMatch = patient.incidentDescription.hospitalName.toLowerCase().includes(textFilter.toLowerCase());
    return textMatch;
  });
}

export function loadHospitalSelector(patients, textFilter) {
  return patients.filter((patient) => {
    const textMatch = patient.hospitalName.toLowerCase().includes(textFilter.toLowerCase());
    return textMatch;
  });
}
