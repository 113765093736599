import React, { Component } from "react";
import { connect } from "react-redux";
import Responsive from "react-responsive";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Button } from "mdbreact";

import Footer from "../../components/app-footer";
import Sidebar from "../../components/app-sidebar";
import AppHeader from "../../components/app-header";

import "./edit-patient-page.scss";
import "react-datepicker/dist/react-datepicker.css";

const Desktop = props => <Responsive {...props} minWidth={1500} />;
const Laptop = props => <Responsive {...props} maxWidth={1499} />;

export class EditPatientPage extends Component {
  constructor(props) {
    super();
    this.state = {
      now: moment(),
      incidentDescription: {
        hospitalName: "",
        overdoseDate: "",
        hospitalArrivalTime: ""
      },
      patientInformation: {
        race: "",
        gender: "",
        age: "",
        countyOfOverdose: "",
        countyOfOverdoseOther: "",
        zipCode: "",
        zipCodeResidence: "",
        housing: "",
        transportation: "",
        hunger: "",
        isVeteran: "",
        responsiveness: "",
        breathing: "",
        pulse: "",
        didPersonSurvive: "",
        paraphernaliDescription: "",
        milligramsNaloxone: "",
        naloxoneAdministered: "",
        methodOverdose: "",
        intentOfUse: "",
        transportingAmbulance: "",
        dispositionOfPatient: "",
        commentsOnHospitalTransfer: "",
        otherComments: "",
        causeOfOverdose: [],
        naloxoneAdministeredAnyoneElse: []
      },
      policeInformation: {
        description: "",
        arrivalTime: "",
        officerOnScene: "",
        shieldNumber: "",
        caseNumber: ""
      },
      formProgress: 0,
      missingValues: [],
      incorrectValues: [],
      initial: true,
      id: ""
    };
  }

  startState = patient => {
    const state = this.state;
    state.incidentDescription.hospitalName =
      patient.incidentDescription.hospitalName;
    state.incidentDescription.overdoseDate =
      patient.incidentDescription.overdoseDate;
    state.incidentDescription.hospitalArrivalTime =
      patient.incidentDescription.hospitalArrivalTime;

    state.patientInformation.race = patient.patientInformation.race;
    state.patientInformation.gender = patient.patientInformation.gender;
    state.patientInformation.age = patient.patientInformation.age;
    state.patientInformation.countyOfOverdose =
      patient.patientInformation.countyOfOverdose;
    state.patientInformation.countyOfOverdoseOther =
      patient.patientInformation.countyOfOverdoseOther;
    state.patientInformation.zipCode = patient.patientInformation.zipCode;
    state.patientInformation.zipCodeResidence =
      patient.patientInformation.zipCodeResidence;
    state.patientInformation.housing = patient.patientInformation.housing;
    state.patientInformation.transportation =
      patient.patientInformation.transportation;
    state.patientInformation.hunger = patient.patientInformation.hunger;
    state.patientInformation.isVeteran = patient.patientInformation.isVeteran;
    state.patientInformation.responsiveness =
      patient.patientInformation.responsiveness;
    state.patientInformation.breathing = patient.patientInformation.breathing;
    state.patientInformation.pulse = patient.patientInformation.pulse;
    state.patientInformation.didPersonSurvive =
      patient.patientInformation.didPersonSurvive;
    state.patientInformation.paraphernaliDescription =
      patient.patientInformation.paraphernaliDescription;
    state.patientInformation.milligramsNaloxone =
      patient.patientInformation.milligramsNaloxone;
    state.patientInformation.naloxoneAdministered =
      patient.patientInformation.naloxoneAdministered;
    state.patientInformation.methodOverdose =
      patient.patientInformation.methodOverdose;
    state.patientInformation.intentOfUse =
      patient.patientInformation.intentOfUse;
    state.patientInformation.transportingAmbulance =
      patient.patientInformation.transportingAmbulance;
    state.patientInformation.dispositionOfPatient =
      patient.patientInformation.dispositionOfPatient;
    state.patientInformation.commentsOnHospitalTransfer =
      patient.patientInformation.commentsOnHospitalTransfer;
    state.patientInformation.otherComments =
      patient.patientInformation.otherComments;
    state.patientInformation.causeOfOverdose =
      patient.patientInformation.causeOfOverdose;
    state.patientInformation.naloxoneAdministeredAnyoneElse =
      patient.patientInformation.naloxoneAdministeredAnyoneElse;

    state.policeInformation.description = patient.policeInformation.description;
    state.policeInformation.arrivalTime = patient.policeInformation.arrivalTime;
    state.policeInformation.officerOnScene =
      patient.policeInformation.officerOnScene;
    state.policeInformation.shieldNumber =
      patient.policeInformation.shieldNumber;
    state.policeInformation.caseNumber = patient.policeInformation.caseNumber;

    state.initial = false;
    state.id = patient._id;

    this.setState(state);
  };

  onIncidentChange = e => {
    const state = this.state;
    state.incidentDescription[e.target.name] = e.target.value;
    this.setState(state);
  };

  onIncidentOverdoseDateChange = date => {
    const state = this.state;
    state.incidentDescription.overdoseDate = date;
    this.setState(state);
  };

  onIncidentHospitalArrivalTimeChange = time => {
    const state = this.state;
    state.incidentDescription.hospitalArrivalTime = time;
    this.setState(state);
  };

  // Patient Information Form Data Binding
  onPatientInformationChange = e => {
    const state = this.state;
    state.patientInformation[e.target.name] = e.target.value;
    this.setState(state);
  };

  // Maintains state of causeOfOverdose list
  onPatientInformationChangeCauseOfOverdose = item => {
    const state = this.state;
    const index = state.patientInformation.causeOfOverdose.indexOf(
      item.target.value
    );
    if (index !== -1) {
      state.patientInformation.causeOfOverdose.splice(index, 1);
    } else {
      state.patientInformation.causeOfOverdose.push(item.target.value);
    }
    this.setState(state);
  };

  // Maintains state of naxoloneAdministeredAnyoneElse list
  onPatientInformationNaxoloneAdministeredAnyoneElse = item => {
    const state = this.state;
    const index = state.patientInformation.naloxoneAdministeredAnyoneElse.indexOf(
      item.target.value
    );
    if (index !== -1) {
      state.patientInformation.naloxoneAdministeredAnyoneElse.splice(index, 1);
    } else {
      state.patientInformation.naloxoneAdministeredAnyoneElse.push(
        item.target.value
      );
    }
    this.setState(state);
  };

  onPoliceInformationChange = e => {
    const state = this.state;
    state.policeInformation[e.target.name] = e.target.value;
    this.setState(state);
  };

  onPoliceInformationArrivalTimeChange = time => {
    const state = this.state;
    state.policeInformation.arrivalTime = time;
    this.setState(state);
  };

  onSubmit = e => {
    const incorrectValues = this.verifyValues();
    if (!incorrectValues.length) {
      const url = process.env.REACT_APP_API_URL;
      const clientUrl = process.env.REACT_APP_CLIENT_URL;

      axios.defaults.headers.common.Authorization = localStorage.getItem(
        "accessToken"
      );
      e.preventDefault();
      axios
        .patch(`${url}api/patientData`, this.state)
        .then(result => {
          console.log(result);
          window.location.replace(`${clientUrl}?updatePatient=true`);
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.setState({ message: "Data upload failed." });
          }
        });

      // axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken');
      // e.preventDefault();
      // axios.post(`${url}api/patientData`, this.state)
      //   .then((result) => {
      //     console.log(result);
      //     window.location.replace(`${clientUrl}?upload=true`);
      //   })
      //   .catch((error) => {
      //     if (error.response.status === 401) {
      //       this.setState({ message: 'Data upload failed.' });
      //     }
      //   });
    } else {
      this.setState({ incorrectValues });
    }
  };

  verifyValues = () => {
    const incorrectValues = [];
    const state = this.state;
    if (
      (isNaN(state.patientInformation.zipCode) ||
        state.patientInformation.zipCode.length !== 5) &&
      state.patientInformation.zipCode
    )
      incorrectValues.push("zipCode");
    if (
      isNaN(state.patientInformation.milligramsNaloxone) &&
      state.patientInformation.milligramsNaloxone
    )
      incorrectValues.push("milligramsNaloxone");
    return incorrectValues;
  };

  renderIncidentDescription = patient => (
    <div>
      <div className="row justify-content-md-left">
        <div className="col-12 card--title">
          <h2 className="w-500">
            Incident Description
            <span className="last-updated-subtitle w-200" />
          </h2>
        </div>
      </div>
      <div className="row justify-content-md-left wrapper--custom card--wrapper">
        <div className="col-6 col--top_margin">
          <label className="grey-text">Hospital</label>
          <select
            defaultValue={patient.incidentDescription.hospitalName}
            onChange={this.onIncidentChange}
            name="hospitalName"
            className="browser-default custom-select"
            required
          >
            <option value="St. Luke's Cornwall Hospital">
              St. Luke's Cornwall Hospital
            </option>
            <option value="Orange Regional Medical Center">
              Orange Regional Medical Center
            </option>
            <option value="Catskill Regional Medical Center">
              Catskill Regional Medical Center
            </option>
            <option value="Ellenville Hospital">Ellenville Hospital</option>
          </select>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Overdose Date</label>
          <DatePicker
            selected={
              this.state.incidentDescription.overdoseDate
                ? moment(
                    this.state.incidentDescription.overdoseDate,
                    "DD-MM-YYYY"
                  )
                : moment()
            }
            className="form-control"
            onChange={this.onIncidentOverdoseDateChange}
          />
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Hospital Arrival Time</label>
          <DatePicker
            selected={this.state.incidentDescription.hospitalArrivalTime}
            onChange={this.onIncidentHospitalArrivalTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="LT"
            timeCaption="Time"
            className="form-control w-100"
          />
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Did the person survive?</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.didPersonSurvive}
            name="didPersonSurvive"
            onChange={this.onPatientInformationChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
      </div>
      <br />
    </div>
  );

  renderPatientInformation = patient => (
    <div>
      <div className="row justify-content-md-left">
        <div className="col-12 card--title">
          <h2 className="w-500">
            Patient Details
            <span className="last-updated-subtitle w-200">
              Prior to Administration of Naloxone
            </span>
          </h2>
        </div>
      </div>
      <div className="row justify-content-md-left wrapper--custom card--wrapper">
        <div className="col-4 col--top_margin">
          <label className="grey-text">Race</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.race}
            name="race"
            onChange={this.onPatientInformationChange}
          >
            <option>Race/Ethnicity</option>
            <option value="African-American/Black">
              African-American/Black
            </option>
            <option value="Hispanic/Latino(a)">Hispanic/Latino(a)</option>
            <option value="Caucasian/White">Caucasian/White</option>
            <option value="Asian/Pacific Islander">
              Asian/Pacific Islander
            </option>
            <option value="Native American">Native American</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
        <div className="col-4 col--top_margin">
          <label className="grey-text">Gender</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.gender}
            name="gender"
            onChange={this.onPatientInformationChange}
          >
            <option>Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="col-4 col--top_margin">
          <label className="grey-text">Age</label>
          <input
            type="text"
            defaultValue={patient.patientInformation.age}
            name="age"
            onChange={this.onPatientInformationChange}
            className="form-control w-100"
            placeholder="Enter age..."
            required
          />
        </div>
        <div className="col-4 col--top_margin">
          <label className="grey-text">Housing</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.housing}
            name="housing"
            onChange={this.onPatientInformationChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="col-4 col--top_margin">
          <label className="grey-text">Transportation</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.transportation}
            name="transportation"
            onChange={this.onPatientInformationChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="col-4 col--top_margin">
          <label className="grey-text">Hunger</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.hunger}
            name="hunger"
            onChange={this.onPatientInformationChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div className="col-12 col--top_margin">
          <label className="grey-text">Is the patient a veteran?</label>
          <select
            className="browser-default custom-select"
            defaultValue="No"
            defaultValue={patient.patientInformation.isVeteran}
            name="isVeteran"
            onChange={this.onPatientInformationChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>
      <div
        className="row justify-content-md-left wrapper--custom card--wrapper"
        style={{ marginTop: "2em" }}
      >
        <div className="col-6 col--top_margin">
          <label className="grey-text">County Where Overdose Occurred</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.countyOfOverdose}
            name="countyOfOverdose"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose County</option>
            <option value="Dutchess">Dutchess</option>
            <option value="Orange">Orange</option>
            <option value="Putnam">Putnam</option>
            <option value="Rockland">Rockland</option>
            <option value="Sullivan">Sullivan</option>
            <option value="Ulster">Ulster</option>
            />
            <option value="Westchester">Westchester</option>
            />
            <option value="Other">Other</option>
            />
          </select>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">If Other</label>
          <input
            type="text"
            defaultValue={patient.patientInformation.countyOfOverdoseOther}
            name="countyOfOverdoseOther"
            onChange={this.onPatientInformationChange}
            className="form-control w-100"
            required
          />
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Zip Code of Overdose</label>
          <input
            type="text"
            defaultValue={patient.patientInformation.zipCode}
            name="zipCode"
            onChange={this.onPatientInformationChange}
            className="form-control w-100"
            required
          />
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">
            Is the patient from the zip code of overdose?
          </label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.zipCodeResidence}
            name="zipCodeResidence"
            onChange={this.onPatientInformationChange}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>

      <div
        className="row justify-content-md-left wrapper--custom card--wrapper"
        style={{ marginTop: "2em" }}
      >
        <div className="col-6 col--top_margin">
          <label className="grey-text">Responsiveness</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.responsiveness}
            name="responsiveness"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose Responsiveness</option>
            <option>Unresponsive</option>
            <option value="Responsive (Sedated)">Responsive (Sedated)</option>
            <option value="Responsive (Alert)">Responsive (Alert)</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Breathing</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.breathing}
            name="breathing"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose Breathing</option>
            <option value="Breathing Fast">Breathing Fast</option>
            <option value="Breathing Slow">Breathing Slow</option>
            <option value="Breathing Normally">Breathing Normally</option>
            <option value="Not Breathing">Not Breathing</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Pulse</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.pulse}
            name="pulse"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose Pulse</option>
            <option value="Fast Pulse">Fast Pulse</option>
            <option value="Slow Pulse">Slow Pulse</option>
            <option value="No Pulse">No Pulse</option>
            <option value="Did Not Check Pulse">Did Not Check Pulse</option>
          </select>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">How was Naloxone Administered</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.naloxoneAdministered}
            name="naloxoneAdministered"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose a method</option>
            <option value="Injected in the muscle">
              Injected in the muscle
            </option>
            <option value="Sprayed in the nose">Sprayed in the nose</option>
            <option value="IV Drip">IV Drip</option>
          </select>
        </div>
        <div className="col-12 col--top_margin">
          <label className="grey-text">Milligrams of Naloxone Used</label>
          <input
            type="text"
            name="milligramsNaloxone"
            defaultValue={patient.patientInformation.milligramsNaloxone}
            onChange={this.onPatientInformationChange}
            className="form-control w-100"
            required
          />
        </div>

        <div className="col-12 col--top_margin">
          <label className="grey-text">
            Comment/Package or Paraphernalia Description
          </label>
          <textarea
            type="text"
            className="form-control w-100"
            defaultValue={patient.patientInformation.paraphernaliDescription}
            name="paraphernaliDescription"
            onChange={this.onPatientInformationChange}
            rows="6"
          />
        </div>
      </div>

      <div
        className="row justify-content-md-left wrapper--custom card--wrapper"
        style={{ marginTop: "2em" }}
      >
        <div className="col-6 col--top_margin">
          <label className="grey-text">Method of Overdose</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.methodOverdose}
            name="methodOverdose"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose a Method</option>
            <option value="Injected Heroin">Injected Heroin</option>
            <option value="Sniffed Heroin">Sniffed Heroin</option>
            <option value="Used Heroin, but unknown method">
              Used Heroin, but unknown method
            </option>
            <option value="No Heroin use">No Heroin use</option>
            <option value="Ingested Pills">Ingested Pills</option>
            <option value="Smoking">Smoking</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Intent</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.intentOfUse}
            name="intentOfUse"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose an Intent</option>
            <option value="Intentional Recreational Use">
              Intentional Recreational Use
            </option>
            <option value="Intentional Attempted Suicide">
              Intentional Attempted Suicide
            </option>
            <option value="Unintentional">Unintentional</option>
            <option value="Unknown">Unknown</option>
          </select>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Transporting Ambulance</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.transportingAmbulance}
            name="transportingAmbulance"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose an Ambulance</option>
            <option value="Beacon Volunteer Ambulance Corps.">
              Beacon Volunteer Ambulance Corps.
            </option>
            <option value="Blooming Grove Volunteer Ambulance Corps.">
              Blooming Grove Volunteer Ambulance Corps.
            </option>
            <option value="Mobile Life Support Services">
              Mobile Life Support Services
            </option>
            <option value="Modena Rescue Squad">Modena Rescue Squad</option>
            <option value="Montgomery Ambulance (Walden)">
              Montgomery Ambulance (Walden)
            </option>
            <option value="New Windsor Volunteer Ambulance Corps.">
              New Windsor Volunteer Ambulance Corps.
            </option>
            <option value="New Paltz Rescue Squad">
              New Paltz Rescue Squad
            </option>
            <option value="Plattekill Fire Rescue">
              Plattekill Fire Rescue
            </option>
            <option value="Town of Newburgh Ambulance Corps.">
              Town of Newburgh Ambulance Corps.
            </option>
            <option value="Town of Wallkill Volunteer Ambulance">
              Town of Wallkill Volunteer Ambulance
            </option>
            <option value="Walk-in">Walk-in</option>
          </select>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">Disposition of Patient</label>
          <select
            className="browser-default custom-select"
            defaultValue={patient.patientInformation.dispositionOfPatient}
            name="dispositionOfPatient"
            onChange={this.onPatientInformationChange}
          >
            <option>Choose Disposition</option>
            <option value="Hospitalized">Hospitalized</option>
            <option value="Released on Own Recognizance (Discharged)">
              Released on Own Recognizance (Discharged)
            </option>
            <option value="Used Heroin, but unknown method">
              Used Heroin, but unknown method
            </option>
            <option value="Against Medical Advice (AMA>">
              Against Medical Advice (AMA)
            </option>
          </select>
        </div>
        <div className="col-8 col--top_margin">
          <label className="grey-text">
            Comments Relating to Hospital Transfer and Patient Presentation
          </label>
          <textarea
            type="text"
            defaultValue={patient.patientInformation.commentsOnHospitalTransfer}
            name="commentsOnHospitalTransfer"
            onChange={this.onPatientInformationChange}
            className="form-control w-100"
            rows="4"
          />
        </div>
        <div className="col-8 col--top_margin">
          <label className="grey-text">Any Other Comments</label>
          <textarea
            type="text"
            name="otherComments"
            defaultValue={patient.patientInformation.otherComments}
            onChange={this.onPatientInformationChange}
            className="form-control w-100"
            rows="4"
          />
        </div>
      </div>

      <div
        className="row justify-content-md-left wrapper--custom card--wrapper"
        style={{ marginTop: "2em" }}
      >
        <div className="col-4 col--top_margin">
          <label className="grey-text">
            Cause of Overdose (Check all that apply)
          </label>
          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Heroin"
                id="defaultCheck1"
              />
              <label className="form-check-label">Heroin</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Benzos/Barbituates"
                id="defaultCheck2"
              />
              <label className="form-check-label">Benzos/Barbituates</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Cocaine/Crack"
                id="defaultCheck1"
              />
              <label className="form-check-label">Cocaine/Crack</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Buprenorphone/Suboxone"
                id="defaultCheck2"
              />
              <label className="form-check-label">Buprenorphone/Suboxone</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Pain Pills"
                id="defaultCheck1"
              />
              <label className="form-check-label">Pain Pills</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Unknown Pills"
                id="defaultCheck2"
              />
              <label className="form-check-label">Unknown Pills</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Unknown Injection"
                id="defaultCheck1"
              />
              <label className="form-check-label">Unknown Injection</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Alcohol"
                id="defaultCheck2"
              />
              <label className="form-check-label">Alcohol</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Methadone"
                id="defaultCheck2"
              />
              <label className="form-check-label">Methadone</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="PCP"
                id="defaultCheck1"
              />
              <label className="form-check-label">PCP</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Unknown"
                id="defaultCheck2"
              />
              <label className="form-check-label">Unknown</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="causeOfOverdose"
                onChange={this.onPatientInformationChangeCauseOfOverdose}
                type="checkbox"
                value="Other"
                id="defaultCheck1"
              />
              <label className="form-check-label">Other</label>
            </div>
          </div>
        </div>
        <div className="col-6 col--top_margin">
          <label className="grey-text">
            Was Naloxone administered by anyone else at the scene (Check all
            that apply)
          </label>
          <div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="naloxoneAdministeredAnyoneElse"
                onChange={
                  this.onPatientInformationNaxoloneAdministeredAnyoneElse
                }
                type="checkbox"
                value="Bystander"
                id="defaultCheck1"
              />
              <label className="form-check-label">Bystander</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="naloxoneAdministeredAnyoneElse"
                onChange={
                  this.onPatientInformationNaxoloneAdministeredAnyoneElse
                }
                type="checkbox"
                value="EMS"
                id="defaultCheck2"
              />
              <label className="form-check-label">EMS</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="naloxoneAdministeredAnyoneElse"
                onChange={
                  this.onPatientInformationNaxoloneAdministeredAnyoneElse
                }
                type="checkbox"
                value="Emergency Room"
                id="defaultCheck1"
              />
              <label className="form-check-label">Emergency Room</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="naloxoneAdministeredAnyoneElse"
                onChange={
                  this.onPatientInformationNaxoloneAdministeredAnyoneElse
                }
                type="checkbox"
                value="Police"
                id="defaultCheck2"
              />
              <label className="form-check-label">Police</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="naloxoneAdministeredAnyoneElse"
                onChange={
                  this.onPatientInformationNaxoloneAdministeredAnyoneElse
                }
                type="checkbox"
                value="Urgent Care"
                id="defaultCheck1"
              />
              <label className="form-check-label">Urgent Care</label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                name="naloxoneAdministeredAnyoneElse"
                onChange={
                  this.onPatientInformationNaxoloneAdministeredAnyoneElse
                }
                type="checkbox"
                value="Other"
                id="defaultCheck2"
              />
              <label className="form-check-label">Other</label>
            </div>
          </div>
        </div>
      </div>

      <br />
    </div>
  );

  renderPoliceInformation = patient => (
    <div>
      <div className="row justify-content-md-left">
        <div className="col-12 card--title">
          <h2 className="w-500">
            Police Information
            <span className="last-updated-subtitle w-200">For Police Only</span>
          </h2>
        </div>
      </div>
      <div className="row justify-content-md-left wrapper--custom card--wrapper">
        <div className="col-4 col--top_margin">
          <label className="grey-text">Arrival Time of Officer on Scene</label>
          <DatePicker
            selected={this.state.policeInformation.arrivalTime}
            onChange={this.onPoliceInformationArrivalTimeChange}
            name="arrivalTime"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="LT"
            timeCaption="Time"
            className="form-control w-100"
          />
        </div>
        <div className="col-12 col--top_margin">
          <label className="grey-text">Police Description</label>
          {/* eslint-disable-next-line */}
          <textarea
            type="text"
            defaultValue={patient.policeInformation.description}
            name="description"
            onChange={this.onPoliceInformationChange}
            className="form-control w-100"
            rows="4"
            name="comments"
          ></textarea>
        </div>
        <div className="col-4 col--top_margin">
          <label className="grey-text">Officer on Scene (Last Name)</label>
          <input
            type="text"
            defaultValue={patient.policeInformation.officerOnScene}
            name="officerOnScene"
            onChange={this.onPoliceInformationChange}
            className="form-control w-100"
            required
          />
        </div>
        <div className="col-4 col--top_margin">
          <label className="grey-text">Shield #</label>
          <input
            type="text"
            defaultValue={patient.policeInformation.shieldNumber}
            name="shieldNumber"
            onChange={this.onPoliceInformationChange}
            className="form-control w-100"
            required
          />
        </div>
        <div className="col-4 col--top_margin">
          <label className="grey-text">Case #</label>
          <input
            type="text"
            defaultValue={patient.policeInformation.caseNumber}
            name="caseNumber"
            onChange={this.onPoliceInformationChange}
            className="form-control w-100"
            required
          />
        </div>
        <div className="col-12 col--top_margin">
          <Button
            color="elegant"
            size="sm"
            style={{ marginLeft: "0" }}
            onClick={this.onSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );

  render() {
    const uploadSuccess = this.props.location
      ? this.props.location.search.substring(1) === "upload=true"
      : false;
    const updateSuccess = this.props.location
      ? this.props.location.search.substring(1) === "update=true"
      : false;
    const inviteSuccess = this.props.location
      ? this.props.location.search.substring(1) === "invite=true"
      : false;
    console.log(this.props.location.state.patientInfo);
    var patient = this.props.location.state.patientInfo;
    if (this.state.initial === true) {
      this.startState(patient);
    }
    return (
      <div>
        <Desktop>
          <Sidebar open={false} activeItem="home" />
          {uploadSuccess ? (
            <div className="row justify-content-md-left">
              <div className="col-12 card--title">
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{ marginBottom: "0em" }}
                >
                  Successfully uploaded patient data.
                </div>
              </div>
            </div>
          ) : null}
          {updateSuccess ? (
            <div className="row justify-content-md-left">
              <div className="col-12 card--title">
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{ marginBottom: "0em" }}
                >
                  Successfully updated user profile
                </div>
              </div>
            </div>
          ) : null}
          {inviteSuccess ? (
            <div className="row justify-content-md-left">
              <div className="col-12 card--title">
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{ marginBottom: "0em" }}
                >
                  Successfully invited user.
                </div>
              </div>
            </div>
          ) : null}
          <AppHeader activeItem="editdata" showSidebarMargins />
          <div className="main-container">
            {this.state.incorrectValues.length ? (
              <div className="col-12 card--title">
                <div className="alert alert-danger" role="alert">
                  The following required fields must be numeric:{" "}
                  {this.state.incorrectValues.join(", ")}
                </div>
              </div>
            ) : null}
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.renderIncidentDescription(patient)}
            </div>
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.renderPatientInformation(patient)}
            </div>
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.renderPoliceInformation(patient)}
            </div>
            <Footer />
          </div>
        </Desktop>

        <Laptop>
          <Sidebar open activeItem="home" />
          {uploadSuccess ? (
            <div className="row justify-content-md-left">
              <div className="col-12 card--title">
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{ marginBottom: "0em" }}
                >
                  Successfully uploaded patient data.
                </div>
              </div>
            </div>
          ) : null}
          {updateSuccess ? (
            <div className="row justify-content-md-left">
              <div className="col-12 card--title">
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{ marginBottom: "0em" }}
                >
                  Successfully updated user profile
                </div>
              </div>
            </div>
          ) : null}
          {inviteSuccess ? (
            <div className="row justify-content-md-left">
              <div className="col-12 card--title">
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{ marginBottom: "0em" }}
                >
                  Successfully invited user.
                </div>
              </div>
            </div>
          ) : null}
          <AppHeader activeItem="editdata" showSidebarMargins={false} />
          <div className="main-container no--sidebar">
            {this.state.incorrectValues.length ? (
              <div className="col-12 card--title">
                <div className="alert alert-danger" role="alert">
                  The following required fields must be numeric:{" "}
                  {this.state.incorrectValues.join(", ")}
                </div>
              </div>
            ) : null}
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.renderIncidentDescription(patient)}
            </div>
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.renderPatientInformation(patient)}
            </div>
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.renderPoliceInformation(patient)}
            </div>
            <Footer />
          </div>
        </Laptop>
      </div>
    );
  }
}

// ==========================================================
// CONNECT
// ----------------------------------------------------------

export default connect(undefined, undefined)(EditPatientPage);
