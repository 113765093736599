import React from 'react';
import { Link } from 'react-router-dom';

import './app-footer.scss';

const FooterPage = () => (
  <div>
    <div className="app-footer">
      <p>Questions?</p>
      <ul>
        <li><a href="mailto:info@1life.io" style={{ color: 'inherit' }}>Email us</a></li>
      </ul>
    </div>
    <div className="container-fluid">
      <div className="row w-100 padding-10">
        <div className="col-2 padding-0 app-footer_text">
          <p>The website is operated and maintained by HVIAS, a 1Life Project</p>
          <div className="app-footer_terms">
            <Link to="/termsandconditions"><p>Terms & Conditions</p></Link>
            <Link to="/privacypolicy"><p>Privacy Policy</p></Link>
          </div>
        </div>
        <div className="col-1" />
        <div className="col-4 app-footer_text">
          <p>
            Other than the content you own, under these Terms, 1Life and/or its licensors own all the intellectual property rights and materials contained in hvias. You are granted limited license only for purposes of viewing the material contained on hvias.
          </p>
          <p>
            Your content must be your own and must not be invading any third-party’s rights. 1Life reserves the right to remove any of your content from hvias at any time without notice.
          </p>
          <p>
            In no event shall 1Life, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  1Life, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of hvias.
          </p>
        </div>
        <div className="col-1" />
        <div className="col-3 app-footer_text">
          <p>
            You hereby indemnify to the fullest extent 1Life from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
          </p>
          <p>
            &copy;2018 The 1Life Project
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default FooterPage;
