import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import axios from "axios";
import { BarLoader } from "react-spinners";
import { reportsActions } from "../core/reports";
import { userActions } from "../core/user";
import { notificationActions } from "../core/notifications";

import HomePage from "./pages/home-page";
import RegisterPage from "./pages/register-page";
import PasswordRecoveryPage from "./pages/password-recovery-page";
import NotFoundPage from "./pages/not-found-page";
import UploadDataPage from "./pages/upload-data-page";
import SinglePatientPage from "./pages/single-patient-page";
import ContactPage from "./pages/contact-page";
import AnalyticsPage from "./pages/analytics-page";
import UpdateProfilePage from "./pages/update-profile-page";
import PrivacyPolicyPage from "./pages/privacy-policy-page";
import TermsAndConditionsPage from "./pages/terms-and-conditions-page";
import InviteUserPage from "./pages/invite-user-page";
import DataOverviewPage from "./pages/data-overview";
import EditPatientPage from "./pages/edit-patient-page";

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      dataFetchCompleted: false,
    };
  }

  componentDidMount() {
    const url = process.env.REACT_APP_LOGOUT_URL;
    if (localStorage.getItem("accessToken")) {
      if (this.validate(localStorage.getItem("accessToken"))) {
        this.fetchData();
      } else {
        localStorage.clear();
        window.location.replace(
          `https://hvias.auth0.com/v2/logout?returnTo=${url}`
        );
      }
    } else {
      localStorage.clear();
      this.authenticator();
    }
  }

  validate(accessToken) {
    const decoded = this.parseJwt(accessToken.substring(6));
    const now = new Date().getTime() / 1000;
    return decoded.exp > now;
  }

  parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }

  fetchData = () => {
    const url = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    axios
      .all([
        axios.get(`${url}api/user/`),
        axios.get(`${url}api/patientData/`),
        axios.get(`${url}api/notificationData/`),
      ])
      .then(
        axios.spread((userResponse, dataResponse, notificationResponse) => {
          this.props.setUser(userResponse.data);
          this.props.setReports(dataResponse.data.reverse());
          this.props.setNotifications(notificationResponse.data.reverse());
          this.setState({ loader: false });
        })
      )
      .catch((userError, dataError, notificationError) => {
        console.log("Login Failed: ", userError, dataError, notificationError);
        localStorage.clear();
      });
  };

  authorize = (code) => {
    const url = process.env.REACT_APP_API_URL;
    axios
      .post(`${url}api/auth/login`, { code })
      .then((result) => {
        localStorage.setItem(
          "accessToken",
          `Bearer ${result.data.access_token}`
        );
        this.fetchData();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          console.log(process.env.NODE_ENV);
          console.log(error);
        }
      });
  };

  authenticator = () => {
    const code = this.props.location.search.includes("code=")
      ? this.props.location.search.substring(6)
      : null;
    localStorage.setItem("hviasCode", code);
    const url = process.env.REACT_APP_CLIENT_URL;
    if (code) {
      this.authorize(code);
    } else {
      window.location.replace(
        `https://hvias.auth0.com/authorize?audience=https://hvias.auth0.com/api/v2/&scope=email&response_type=code&client_id=Cajal0HfPAnG7K0p53cA3zHyi9gDv0ul&redirect_uri=${url}`
      );
    }
  };

  render() {
    return (
      <div>
        {this.state.loader ? (
          <BarLoader width="100%" color="#353fc78f" />
        ) : (
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/viewData" component={DataOverviewPage} />
            <Route exact path="/uploadData" component={UploadDataPage} />
            <Route path="/patient/:id" component={SinglePatientPage} />
            <Route path="/editPatient/:id" component={EditPatientPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/passwordrecovery" component={PasswordRecoveryPage} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/analytics" component={AnalyticsPage} />
            <Route path="/updateProfile" component={UpdateProfilePage} />
            <Route path="/privacypolicy" component={PrivacyPolicyPage} />
            <Route
              path="/termsandconditions"
              component={TermsAndConditionsPage}
            />
            <Route path="/inviteuser" component={InviteUserPage} />
            <Route component={NotFoundPage} />
          </Switch>
        )}
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

const mapDispatchToProps = (dispatch) => ({
  setReports: (patientData) => dispatch(reportsActions.setReports(patientData)),
  setUser: (userData) => dispatch(userActions.setUser(userData)),
  setNotifications: (notificationData) =>
    dispatch(notificationActions.setNotifications(notificationData)),
});

// ==========================================================
// CONNECT
// ----------------------------------------------------------

export default withRouter(
  connect(
    undefined, // mapStateToProps
    mapDispatchToProps // mapDispatchToProps
  )(App)
);
