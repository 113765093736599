import React, { Component } from "react";
import ReactDataGrid from "react-data-grid";
import { connect } from "react-redux";

//console.log("process env", process.env);

class NotificationDateTimeFormatter extends Component {
  render() {
    const createdAt = new Date(this.props.value);
    return <div>{createdAt.toLocaleString("en-US")}</div>;
  }
}

class ZipCodesFormatter extends Component {
  render() {
    const zipCodes = this.props.value.join(", ");
    return <div>{zipCodes}</div>;
  }
}

class OverdoseAmountFormatter extends Component {
  render() {
    const overdoseAmount = this.props.value;
    return <div>{overdoseAmount}</div>;
  }
}

class NotificationTable extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "createdAt",
        name: "Notification Date",
        resizable: true,
        formatter: NotificationDateTimeFormatter,
      },
      {
        key: "zipcodes",
        name: "Zip Codes Affected",
        resizable: true,
        formatter: ZipCodesFormatter,
      },
      {
        key: "numberOfOverdoses",
        name: "Number of Overdoses",
        resizable: true,
        formatter: OverdoseAmountFormatter,
      },
    ];
  }

  getSize = () => this.getRows().length;

  rowGetter = (i) => {
    if (this.props.notification[i] !== undefined) {
      return this.props.notification[i];
    }
  };

  render() {
    return (
      <div>
        <ReactDataGrid
          columns={this.columns}
          minHeight={550}
          rowGetter={this.rowGetter}
          rowsCount={this.props.notification.length}
          rowHeight={38}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notification: state.notification.notification,
});

export default connect(mapStateToProps, null)(NotificationTable);
