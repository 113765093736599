import React from 'react';
import { Button } from 'mdbreact';

import './not-found-page.scss';

const NotFoundPage = props => (
  <div className="container-fluid">
    <div className="row full--height container--404">
      <div className="col-12 align-self-center">
        <h1>404</h1>
        <p>Unfortunately, the page you are looking for cannot be found.</p>
        <Button className="btn--return" color="elegant" onClick={props.history.goBack}>Return</Button>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
