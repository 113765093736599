import React, { PureComponent } from "react";
import Sidebar from "react-sidebar";
import { Link } from "react-router-dom";
import { Button, Fa } from "mdbreact";

import "./app-sidebar.scss";

class SidebarWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: this.props.open,
      remainUndocked: false,
      activeItem: props.activeItem
    };
  }

  toggleSidebar = () => {
    this.setState(prevState => {
      const remainUndockedCopy = !prevState.remainUndocked;
      return { remainUndocked: remainUndockedCopy };
    });
  };

  activeSidebarItem = () => {
    const activeItem = this.state.activeItem;
    if (activeItem === "home") {
      return (
        <div>
          <div>
            <ul className="sidebar-item">
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                className="sidebar-item"
              >
                <li className="sidebar-item_active">
                  <Fa icon="home" /> Home
                </li>
              </Link>
            </ul>
          </div>
        </div>
      );
    }
    if (activeItem === "analytics") {
      return (
        <div>
          <ul className="sidebar-item">
            <Link
              to="/"
              style={{ textDecoration: "none" }}
              className="sidebar-item"
            >
              <li>
                <Fa icon="home" /> Home
              </li>
            </Link>
            <Link
              to="/analytics"
              style={{ textDecoration: "none" }}
              className="sidebar-item"
            >
              <li className="sidebar-item_active">
                <Fa icon="pie-chart" /> Analytics
              </li>
            </Link>
          </ul>
        </div>
      );
    }
    return (
      <div>
        <ul className="sidebar-item">
          <Link
            to="/"
            style={{ textDecoration: "none" }}
            className="sidebar-item"
          >
            <li>
              <Fa icon="home" /> Home
            </li>
          </Link>
          <Link
            to="/analytics"
            style={{ textDecoration: "none" }}
            className="sidebar-item"
          >
            <li>
              <Fa icon="pie-chart" /> Analytics
            </li>
          </Link>
        </ul>
      </div>
    );
  };

  onOverlay = () => {
    const { sidebarOpen, remainUndocked } = this.state;
    if (!sidebarOpen) {
      return (
        <Sidebar
          sidebar={
            <div>
              <div className="sidebar--title">
                <p>HVIAS</p>
              </div>
              {this.activeSidebarItem()}
            </div>
          }
          docked
          styles={{
            sidebar: {
              background: "#30363d",
              width: "12.5%",
              position: "fixed"
            }
          }}
        />
      );
    }

    return (
      <div>
        <Sidebar
          sidebar={
            <div>
              <div className="sidebar--title">
                <p>HVIAS</p>
              </div>
              {this.activeSidebarItem()}
            </div>
          }
          open={sidebarOpen && remainUndocked}
          onSetOpen={this.toggleSidebar}
          styles={{
            sidebar: {
              background: "#30363d",
              width: "20%",
              position: "fixed"
            }
          }}
        />
        <Button color="elegant" size="sm" onClick={this.toggleSidebar}>
          Menu
        </Button>
      </div>
    );
  };

  render() {
    return <div>{this.onOverlay()}</div>;
  }
}

export default SidebarWrapper;
