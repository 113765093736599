import React, { Component } from "react";
import Responsive from "react-responsive";
import { connect } from "react-redux";
import { Button } from "mdbreact";
import axios from "axios";
import AppSidebar from "../../components/app-sidebar";
import AppHeader from "../../components/app-header";

const Desktop = (props) => <Responsive {...props} minWidth={1500} />;
const Laptop = (props) => <Responsive {...props} maxWidth={1499} />;

export class InviteUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        hospital: "St. Luke's Cornwall Hospital",
        admin: false,
      },
      missingValues: [],
      url:
        process.env.NODE_ENV === "production"
          ? "https://www.hvias.com/"
          : "http://localhost:8080/",
    };
  }

  onCheck = (e) => {
    const state = this.state;
    if (state.user.admin) {
      state.user.admin = false;
      this.setState(state);
    } else {
      state.user.admin = true;
      this.setState(state);
    }
  };

  onChange = (e) => {
    const state = this.state;
    state.user[e.target.name] = e.target.value;
    this.setState(state);
    //console.log(this.state);
  };

  onSubmit = (e) => {
    const serverUrl = process.env.REACT_APP_API_URL;
    const clientUrl = process.env.REACT_APP_CLIENT_URL;

    const missingValues = this.checkValues();
    if (!missingValues.length) {
      axios.defaults.headers.common.Authorization =
        localStorage.getItem("accessToken");
      e.preventDefault();
      axios
        .post(`${serverUrl}api/user/inviteUser`, this.state)
        .then((result) => {
          console.log(result);
          //window.location.replace(`${clientUrl}?invite=true`);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.setState({ message: "Invite User Failed" });
            console.log(error);
          }
        });
    } else {
      this.setState({ missingValues });
    }
  };

  checkValues = () => {
    const missingValues = [];
    if (!this.state.user.email) {
      missingValues.push("Email");
    }
    if (!this.state.user.hospital) {
      missingValues.push("hospital");
    }
    return missingValues;
  };

  pageContent() {
    return (
      <div>
        <div className="row justify-content-md-center">
          <div className="col-6 col--top_margin">
            <label className="grey-text">Email:</label>
            <input
              type="text"
              name="email"
              onChange={this.onChange}
              className="form-control w-100"
              required
            />
          </div>
          <div className="col-6 col--top_margin">
            <label className="grey-text">Hospital:</label>
            <select
              name="hospital"
              onChange={this.onChange}
              className="form-control w-100"
              required
            >
              <option value="St. Luke's Cornwall Hospital">
                St. Luke's Cornwall Hospital
              </option>
              <option value="Orange Regional Medical Center">
                Orange Regional Medical Center
              </option>
              <option value="Catskill Regional Medical Center">
                Catskill Regional Medical Center
              </option>
              <option value="Ellenville Hospital">Ellenville Hospital</option>
            </select>
          </div>
          <div className="col-12 col--top_margin">
            <input
              className="admin"
              name="admin"
              onChange={this.onCheck}
              type="checkbox"
              value="Admin"
              id="defaultCheck1"
            />
            <label className="grey-text">Is this user an admin?</label>
          </div>
          <div className="col-12 col--top_margin">
            <Button
              color="elegant"
              size="sm"
              style={{ marginLeft: "0" }}
              onClick={this.onSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Desktop>
          <AppSidebar open={false} activeItem="home" />
          <AppHeader activeItem="inviteUser" showSidebarMargins />
          <div className="main-container">
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.state.missingValues.length ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    The following required fields are missing:{" "}
                    {this.state.missingValues.join(", ")}
                  </div>
                </div>
              ) : null}
              <div className="col-12 card--title">
                <h4 className="w-500">
                  Invite User
                  <span className="last-updated-subtitle w-200" />
                </h4>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12" style={{ marginTop: "1em" }}>
                  {this.pageContent()}
                </div>
              </div>
            </div>
          </div>
        </Desktop>
        <Laptop>
          <AppSidebar open activeItem="home" />
          <AppHeader activeItem="inviteUser" showSidebarMargins={false} />
          <div className="main-container no--sidebar">
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.state.missingValues.length ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    The following required fields are missing:{" "}
                    {this.state.missingValues.join(", ")}
                  </div>
                </div>
              ) : null}
              <div className="col-12 card--title">
                <h4 className="w-500">
                  Invite User
                  <span className="last-updated-subtitle w-200" />
                </h4>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12" style={{ marginTop: "1em" }}>
                  {this.pageContent()}
                </div>
              </div>
            </div>
          </div>
        </Laptop>
      </div>
    );
  }
}

export default connect(undefined, undefined)(InviteUserPage);
