import _ from 'lodash';

export function loadHospitals(patientData) {
  console.log(patientData);
  const hospitalList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          hospitalList.push(patientData[data].data[key].incidentDescription.hospitalName);
        }
      }
    }
  }
  const hospitalListCount = _.countBy(hospitalList);
  const formattedList = [];
  for (const key2 in hospitalListCount) {
    formattedList.push({
      name: key2,
      value: hospitalListCount[key2],
    });
  }
  return formattedList;
}

export function loadZipcodes(patientData) {
  const zipCodesList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            zipCodesList.push(patientData[data].data[key].patientInformation.zipCode);
          } else if (patientData.hospitalSelect === 'all') {
            zipCodesList.push(patientData[data].data[key].patientInformation.zipCode);
          }
        }
      }
    }
  }
  const zipCodesListCount = _.countBy(zipCodesList);
  const formattedList = [];
  for (const key2 in zipCodesListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'unknown',
        incidents: zipCodesListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        incidents: zipCodesListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadCounty(patientData) {
  const countyList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            countyList.push(patientData[data].data[key].patientInformation.countyOfOverdose);
          } else if (patientData.hospitalSelect === 'all') {
            countyList.push(patientData[data].data[key].patientInformation.countyOfOverdose);
          }
        }
      }
    }
  }
  const countyListCount = _.countBy(countyList);
  const formattedList = [];
  for (const key2 in countyListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'unknown',
        incidents: countyListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        incidents: countyListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadSurvivors(patientData) {
  const survivorList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            survivorList.push(patientData[data].data[key].patientInformation.didPersonSurvive);
          } else if (patientData.hospitalSelect === 'all') {
            survivorList.push(patientData[data].data[key].patientInformation.didPersonSurvive);
          }
        }
      }
    }
  }

  const cleanSurvivorList = survivorList.map((el) => {
    if (el === '') {
      return 'Unknown';
    }
    return el;
  });
  const survivorListCount = _.countBy(cleanSurvivorList);
  const formattedList = [];
  for (const key2 in survivorListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: survivorListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: survivorListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadAge(patientData) {
  const ageList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            ageList.push(patientData[data].data[key].patientInformation.age);
          } else if (patientData.hospitalSelect === 'all') {
            ageList.push(patientData[data].data[key].patientInformation.age);
          }
        }
      }
    }
  }
  const ageListCount = _.countBy(ageList);
  const formattedList = [];
  for (const key2 in ageListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        amt: ageListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        amt: ageListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadGender(patientData) {
  const genderList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            genderList.push(patientData[data].data[key].patientInformation.gender);
          } else if (patientData.hospitalSelect === 'all') {
            genderList.push(patientData[data].data[key].patientInformation.gender);
          }
        }
      }
    }
  }
  const genderListCount = _.countBy(genderList);
  const formattedList = [];
  for (const key2 in genderListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: genderListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: genderListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadEthnicity(patientData) {
  const ethnicityList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            ethnicityList.push(patientData[data].data[key].patientInformation.race);
          } else if (patientData.hospitalSelect === 'all') {
            ethnicityList.push(patientData[data].data[key].patientInformation.race);
          }
        }
      }
    }
  }

  const cleanEthnicityList = ethnicityList.map((el) => {
    if (el === '') {
      return 'Unknown';
    }
    return el;
  });

  const ethnicityListCount = _.countBy(cleanEthnicityList);
  const formattedList = [];
  for (const key2 in ethnicityListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: ethnicityListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: ethnicityListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadMGNaloxone(patientData) {
  const naloxoneList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            naloxoneList.push(patientData[data].data[key].patientInformation.milligramsNaloxone);
          } else if (patientData.hospitalSelect === 'all') {
            naloxoneList.push(patientData[data].data[key].patientInformation.milligramsNaloxone);
          }
        }
      }
    }
  }
  const naloxoneListCount = _.countBy(naloxoneList);
  const formattedList = [];
  for (const key2 in naloxoneListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: naloxoneListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: naloxoneListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadOverdoses(patientData) {
  const overdosesList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            overdosesList.push(patientData[data].data[key].patientInformation.methodOverdose);
          } else if (patientData.hospitalSelect === 'all') {
            overdosesList.push(patientData[data].data[key].patientInformation.methodOverdose);
          }
        }
      }
    }
  }
  const overdosesListCount = _.countBy(overdosesList);
  const formattedList = [];
  for (const key2 in overdosesListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: overdosesListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: overdosesListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadIntent(patientData) {
  const intentList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            intentList.push(patientData[data].data[key].patientInformation.intentOfUse);
          } else if (patientData.hospitalSelect === 'all') {
            intentList.push(patientData[data].data[key].patientInformation.intentOfUse);
          }
        }
      }
    }
  }

  const cleanIntentList = intentList.map((el) => {
    if (el === '') {
      return 'Unknown';
    }
    return el;
  });

  const intentListCount = _.countBy(cleanIntentList);
  const formattedList = [];
  for (const key2 in intentListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: intentListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: intentListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadArrivalTime(patientData) {
  const arrivalTimeList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            arrivalTimeList.push(patientData[data].data[key].incidentDescription.hospitalArrivalTime);
          } else if (patientData.hospitalSelect === 'all') {
            arrivalTimeList.push(patientData[data].data[key].incidentDescription.hospitalArrivalTime);
          }
        }
      }
    }
  }
  const arrivalTimeListCount = _.countBy(arrivalTimeList);
  return arrivalTimeListCount;
}

export function loadDisposition(patientData) {
  const dispositionList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            dispositionList.push(patientData[data].data[key].patientInformation.dispositionOfPatient);
          } else if (patientData.hospitalSelect === 'all') {
            dispositionList.push(patientData[data].data[key].patientInformation.dispositionOfPatient);
          }
        }
      }
    }
  }
  const dispositionListCount = _.countBy(dispositionList);
  const formattedList = [];
  for (const key2 in dispositionListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: dispositionListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: dispositionListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadComments(patientData) {
  const commentsList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          commentsList.push(patientData[data].data[key].patientInformation.otherComments);
        }
      }
    }
  }
  const cleanedList = commentsList.filter(i => i);
  console.log(cleanedList);
  return cleanedList;
}

export function loadHunger(patientData) {
  const hungerList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            hungerList.push(patientData[data].data[key].incidentDescription.hunger);
          } else if (patientData.hospitalSelect === 'all') {
            hungerList.push(patientData[data].data[key].incidentDescription.hunger);
          }
        }
      }
    }
  }
  const hungerListCount = _.countBy(hungerList);
  const formattedList = [];
  for (const key2 in hungerListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: hungerListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: hungerListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadHousing(patientData) {
  const housingList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            housingList.push(patientData[data].data[key].patientInformation.housing);
          } else if (patientData.hospitalSelect === 'all') {
            housingList.push(patientData[data].data[key].patientInformation.housing);
          }
        }
      }
    }
  }
  const housingListCount = _.countBy(housingList);
  const formattedList = [];
  for (const key2 in housingListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: housingListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: housingListCount[key2],
      });
    }
  }
  return formattedList;
}

export function loadTransportation(patientData) {
  const transportationList = [];
  if (patientData) {
    for (const data in patientData) {
      if (patientData.hasOwnProperty(data)) {
        for (const key in patientData[data].data) {
          if (patientData.hospitalSelect === patientData[data].data[key].incidentDescription.hospitalName) {
            transportationList.push(patientData[data].data[key].patientInformation.transportation);
          } else if (patientData.hospitalSelect === 'all') {
            transportationList.push(patientData[data].data[key].patientInformation.transportation);
          }
        }
      }
    }
  }
  const transportationListCount = _.countBy(transportationList);
  const formattedList = [];
  for (const key2 in transportationListCount) {
    if (key2 === '') {
      formattedList.push({
        name: 'Unknown',
        value: transportationListCount[key2],
      });
    } else {
      formattedList.push({
        name: key2,
        value: transportationListCount[key2],
      });
    }
  }
  return formattedList;
}
