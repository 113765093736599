import { userActions } from './actions';

const userDefaultState = {
  user: [],
};

export function userReducer(state = userDefaultState, { payload, type }) {
  switch (type) {
    case userActions.SET_USER:
      return {
        ...state,
        user: payload.userData,
      };
    default:
      return state;
  }
}
