export const analyticsActions = {
  SET_PATIENT_DATA: 'SET_PATIENT_DATA',
  SET_PATIENT_HOSPITAL: 'SET_PATIENT_HOSPITAL',
  SET_PATIENT_INTENT: 'SET_PATIENT_INTENT',
  SET_PATIENT_NALOXONE_ADMINISTERED: 'SET_PATIENT_NALOXONE_ADMINISTERED',
  SET_PATIENT_PULSE: 'SET_PATIENT_PULSE',
  SET_PATIENT_DISPOSITION: 'SET_PATIENT_DISPOSITION',
  SET_PATIENT_RACE: 'SET_PATIENT_RACE',
  SET_PATIENT_CAUSEOFOVERDOSE: 'SET_PATIENT_CAUSEOFOVERDOSE',
  SET_PATIENT_NALOXONE_ADMINISTERED_ELSE: 'SET_PATIENT_NALOXONE_ADMINISTERED_ELSE',
  SET_PATIENT_HOUSING: 'SET_PATIENT_HOUSING',
  SET_PATIENT_HUNGER: 'SET_PATIENT_HUNGER',
  SET_PATIENT_TRANSPORTATION: 'SET_PATIENT_TRANSPORTATION',
  SET_HOSPITAL_SELECT: 'SET_HOSPITAL_SELECT',

  setPatientData: patientData => ({
    type: analyticsActions.SET_PATIENT_DATA,
    payload: {
      patientData,
    },
  }),

  setHospitalSelect: hospital => ({
    type: analyticsActions.SET_HOSPITAL_SELECT,
    payload: {
      hospital,
    },
  }),

  setPatientHospital: patientHospital => ({
    type: analyticsActions.SET_PATIENT_HOSPITAL,
    payload: {
      patientHospital,
    },
  }),

  setPatientIntent: patientIntent => ({
    type: analyticsActions.SET_PATIENT_INTENT,
    payload: {
      patientIntent,
    },
  }),

  setPatientNaloxoneAdminstered: naloxoneAdministered => ({
    type: analyticsActions.SET_PATIENT_NALOXONE_ADMINISTERED,
    payload: {
      naloxoneAdministered,
    },
  }),

  setPatientPulse: patientPulse => ({
    type: analyticsActions.SET_PATIENT_PULSE,
    payload: {
      patientPulse,
    },
  }),

  setPatientDisposition: patientDisposition => ({
    type: analyticsActions.SET_PATIENT_DISPOSITION,
    payload: {
      patientDisposition,
    },
  }),

  setPatientRace: patientRace => ({
    type: analyticsActions.SET_PATIENT_RACE,
    payload: {
      patientRace,
    },
  }),

  setPatientCauseOfOverdose: patientCauseOfOverdose => ({
    type: analyticsActions.SET_PATIENT_CAUSEOFOVERDOSE,
    payload: {
      patientCauseOfOverdose,
    },
  }),

  setPatientNaloxoneAdminsteredElse: naloxoneAdministeredElse => ({
    type: analyticsActions.SET_PATIENT_NALOXONE_ADMINISTERED_ELSE,
    payload: {
      naloxoneAdministeredElse,
    },
  }),

  setPatientHunger: patientHunger => ({
    type: analyticsActions.SET_PATIENT_HUNGER,
    payload: {
      patientHunger,
    },
  }),

  setPatientHousing: patientHousing => ({
    type: analyticsActions.SET_PATIENT_HOUSING,
    payload: {
      patientHousing,
    },
  }),

  setPatientTransportation: patientTransportation => ({
    type: analyticsActions.SET_PATIENT_TRANSPORTATION,
    payload: {
      patientTransportation,
    },
  }),
};
