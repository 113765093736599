import { reportsActions } from './actions';

const reportsDefaultState = {
  reports: [],
};

export function reportsReducer(state = reportsDefaultState, { payload, type }) {
  switch (type) {
    case reportsActions.SET_REPORTS:
      return {
        ...state,
        reports: payload.mockData,
      };
    case reportsActions.SET_SELECTED_REPORTS:
      return {
        ...state,
        selectedReports: payload.selectedReports,
      };
    default:
      return state;
  }
}
