export const reportsActions = {
  SET_REPORTS: 'SET_REPORTS',
  SET_SELECTED_REPORTS: 'SET_SELECTED_REPORTS',

  setReports: mockData => ({
    type: reportsActions.SET_REPORTS,
    payload: {
      mockData,
    },
  }),

  setSelectedReports: selectedReports => ({
    type: reportsActions.SET_SELECTED_REPORTS,
    payload: {
      selectedReports,
    },
  }),
};
