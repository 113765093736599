export const userActions = {
  SET_USER: 'SET_USER',

  setUser: userData => ({
    type: userActions.SET_USER,
    payload: {
      userData,
    },
  }),
};
