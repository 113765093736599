import React, { Component } from "react";
import ReactDataGrid from "react-data-grid";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadReportsSelector } from "../../../core/reports";
import ReportsContextMenu from "./reports-context-menu";
import "./reports-table.scss";

class HospitalNameFormatter extends Component {
  render() {
    const incidentDescription = this.props.value;
    return <div>{incidentDescription.hospitalName}</div>;
  }
}

class OverdoseDateFormatter extends Component {
  render() {
    const incidentDescription = this.props.value;
    const date = new Date(incidentDescription.overdoseDate);
    return <div>{date.toLocaleString("en-US")}</div>;
  }
}

class hospitalArrivalTimeFormatter extends Component {
  render() {
    const incidentDescription = this.props.value;
    const date = new Date(incidentDescription.hospitalArrivalTime);
    return <div>{date.toLocaleString("en-US")}</div>;
  }
}

class zipCodeFormatter extends Component {
  render() {
    const patientInformation = this.props.value;
    return <div>{patientInformation.zipCode}</div>;
  }
}

class survivalFormatter extends Component {
  render() {
    const patientInformation = this.props.value;
    return <div>{patientInformation.didPersonSurvive}</div>;
  }
}

class ReportsTable extends Component {
  static propTypes = {
    reports: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        key: "incidentDescription",
        name: "Hospital Name",
        resizable: true,
        formatter: HospitalNameFormatter
      },
      {
        key: "incidentDescription",
        name: "Overdose Date",
        resizable: true,
        formatter: OverdoseDateFormatter
      },
      {
        key: "incidentDescription",
        name: "Hospital Arrival Time",
        resizable: true,
        formatter: hospitalArrivalTimeFormatter
      },
      {
        key: "patientInformation",
        name: "Zip Code",
        resizable: true,
        formatter: zipCodeFormatter
      },
      {
        key: "patientInformation",
        name: "Did Patient Survive?",
        resizable: true,
        formatter: survivalFormatter
      }
    ];
  }

  getSize = () => this.getRows().length;

  rowGetter = i => {
    if (this.props.reports[i] !== undefined) {
      return this.props.reports[i];
    }
  };

  render() {
    return (
      <div>
        <ReactDataGrid
          contextMenu={<ReportsContextMenu reports={this.props.reports} />}
          columns={this.columns}
          minHeight={550}
          rowGetter={this.rowGetter}
          rowsCount={this.props.reports.length}
          rowHeight={38}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  textFilter: state.search.textFilter,
  reports: loadReportsSelector(state.reports.reports, state.search.textFilter)
});

// ==========================================================
// CONNECT
// ----------------------------------------------------------

export default connect(
  mapStateToProps,
  null
)(ReportsTable);
