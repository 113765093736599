import React, { Component } from "react";
import { connect } from "react-redux";
import Responsive from "react-responsive";
import axios from "axios";

import { BeatLoader } from "react-spinners";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { analyticsActions } from "../../../core/analytics";
import {
  loadZipcodes,
  loadHospitals,
  loadCounty,
  loadSurvivors,
  loadAge,
  loadGender,
  loadEthnicity,
  loadOverdoses,
  loadIntent,
  loadArrivalTime,
  loadDisposition,
  loadComments,
  loadMGNaloxone,
  loadHousing,
  loadHunger,
  loadTransportation,
} from "../../../core/analytics";

import Footer from "../../components/app-footer";
import AppSidebar from "../../components/app-sidebar";
import AppHeader from "../../components/app-header";

const Desktop = (props) => <Responsive {...props} minWidth={1919} />;
const Laptop = (props) => <Responsive {...props} maxWidth={1918} />;

const COLORS = ["#8B4513", "#7B68EE", "#00FA9A", "#00BFFF", "#708090"];
const COLORSTWO = ["#1890a0", "#1a3347", "#2ca05d", "#FF8042", "#3CB371"];
const COLORSTHREE = ["#FFEBCD", "#DA70D6", "#FF6347", "#3CB371"];
const COLORSFOUR = ["#9966CC", "#00FFFF", "#FF1493", "#FF0000"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export class AnalyticsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      activeIndex: 0,
      hospitalSelect: "all",
    };
  }

  componentDidMount() {
    const url = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    axios
      .all([axios.get(url + "api/patientData/")])
      .then(
        axios.spread((patientDataResponse) => {
          this.props.setPatientData(patientDataResponse);
          this.props.setHospitalSelect(this.state.hospitalSelect);
          console.log(patientDataResponse);
        })
      )
      .then(() => {
        this.setState({ loader: false });
        function fixDiv() {
          document.getElementById("broken-div");
        }
      })
      .catch((patientDataError) => {
        console.log(patientDataError);
      });
  }

  getComments = () => {
    const commentsList = this.props.commentsList.map((i) => (
      <li key={i}>{i}</li>
    ));
    return commentsList;
  };

  getInitialState = () => ({
    activeIndex: 0,
  });

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  onHospitalSelect = (e) => {
    this.props.setHospitalSelect(e.target.value);
    this.setState({ hospitalSelect: e.target.value });
  };

  render() {
    const uploadSuccess = this.props.location
      ? this.props.location.search.substring(1) === "upload=true"
      : false;
    const updateSuccess = this.props.location
      ? this.props.location.search.substring(1) === "update=true"
      : false;
    const inviteSuccess = this.props.location
      ? this.props.location.search.substring(1) === "invite=true"
      : false;
    const updatePatientSuccess = this.props.location
      ? this.props.location.search.substring(1) === "updatePatient=true"
      : false;
    return (
      <div>
        {this.state.loader ? (
          <div>
            <Desktop>
              <AppSidebar open={false} activeItem="home" />
              <AppHeader activeItem="analytics" showSidebarMargins />
              <div className="main-container">
                <div className="container-fluid" style={{ paddingTop: "2em" }}>
                  <div
                    className="row justify-content-md-left"
                    style={{ marginBottom: "1em" }}
                  >
                    <div className="col-12 card--title">
                      <h2 className="w-500">Data Analytics</h2>
                    </div>
                  </div>
                  <div className="row justify-content-md-left wrapper--custom card--wrapper">
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <BeatLoader width="100%" height="10" color="#353fc78f" />
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </Desktop>
            <Laptop>
              <AppSidebar open activeItem="home" />
              <AppHeader activeItem="analytics" showSidebarMargins={false} />
              <div className="main-container no--sidebar">
                <div className="container-fluid" style={{ paddingTop: "2em" }}>
                  <div
                    className="row justify-content-md-left"
                    style={{ marginBottom: "1em" }}
                  >
                    <div className="col-12 card--title">
                      <h2 className="w-500">
                        Data Analytics
                        <span className="last-updated-subtitle w-200" />
                      </h2>
                    </div>
                  </div>
                  <div className="row justify-content-md-left wrapper--custom card--wrapper">
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <BeatLoader width="100%" height="10" color="#353fc78f" />
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </Laptop>
          </div>
        ) : (
          <div>
            <Desktop>
              <AppSidebar open={false} activeItem="home" />
              <AppHeader activeItem="analytics" showSidebarMargins />
              <div className="main-container">
                <div className="container-fluid" style={{ paddingTop: "2em" }}>
                  {uploadSuccess ? (
                    <div className="row justify-content-md-left">
                      <div className="col-12 card--title">
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginBottom: "0em" }}
                        >
                          Successfully uploaded patient data.
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {updateSuccess ? (
                    <div className="row justify-content-md-left">
                      <div
                        className="col-12 card--title"
                        style={{ marginTop: "1em" }}
                      >
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginBottom: "0em" }}
                        >
                          Successfully updated user profile
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {inviteSuccess ? (
                    <div className="row justify-content-md-left">
                      <div
                        className="col-12 card--title"
                        style={{ marginTop: "1em" }}
                      >
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginBottom: "0em" }}
                        >
                          Successfully invited user.
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {updatePatientSuccess ? (
                    <div className="row justify-content-md-left">
                      <div
                        className="col-12 card--title"
                        style={{ marginTop: "1em" }}
                      >
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginBottom: "0em" }}
                        >
                          Successfully updated patient data.
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className="row justify-content-md-left"
                    style={{ marginBottom: "1em" }}
                  >
                    <div className="col-12 card--title">
                      <h2 className="w-500">
                        Data Analytics
                        <span className="last-updated-subtitle w-200">
                          An overview of incidents reported
                        </span>
                      </h2>
                    </div>
                    <div className="col-6 card--title">
                      <h5>Select Hospital</h5>
                      <select
                        value={this.state.hospitalSelect}
                        defaultValue="all"
                        onChange={this.onHospitalSelect}
                        name="hospitalName"
                        className="browser-default custom-select"
                        required
                      >
                        <option value="all">All Hospitals</option>
                        <option value="St. Luke's Cornwall Hospital">
                          St. Luke's Cornwall Hospital
                        </option>
                        <option value="Orange Regional Medical Center">
                          Orange Regional Medical Center
                        </option>
                        <option value="Catskill Regional Medical Center">
                          Catskill Regional Medical Center
                        </option>
                        <option value="Ellenville Hospital">
                          Ellenville Hospital
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ width: "50%", marginRight: "1em", height: "40em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incident Overview By Hospital</h4>
                      <p>
                        The number of incidents per hospital.
                        <strong>PV</strong> is the point value number of
                        incidents and
                        <strong>rate</strong> represents the percentage of the
                        total incidents.
                      </p>
                      <PieChart
                        width={700}
                        height={500}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.hospitalIncidents}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={160}
                        >
                          {this.props.hospitalIncidents.map((entry, index) => (
                            <Cell fill={COLORSTWO[index % COLORSTWO.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "40em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Zipcodes</h4>
                      <p>
                        The number of incidents that occurred by a specific
                        zipcode.
                      </p>
                      <br />
                      <BarChart
                        width={400}
                        height={350}
                        data={this.props.zipCodes}
                        margin={{
                          top: 55,
                          right: 30,
                          left: 20,
                          bottom: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label
                            value="Zipcodes"
                            position="bottom"
                            offset={0}
                          />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Bar dataKey="incidents" fill="#34AA44">
                          {this.props.zipCodes.map((entry, index) => (
                            <Cell fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ marginRight: "0em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents per County</h4>
                      <p>The number of incidents by county region.</p>
                      <BarChart
                        width={335}
                        height={250}
                        data={this.props.countyIncidents}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 10,
                          bottom: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label value="County" position="bottom" offset={0} />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Bar dataKey="incidents" fill="#E6492D">
                          {this.props.countyIncidents.map((entry, index) => (
                            <Cell fill={COLORSTWO[index % COLORSTWO.length]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{
                      marginRight: "1em",
                      marginLeft: "1em",
                      height: "25em",
                    }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Survival Rate</h4>
                      <p>Did patient survive?</p>
                      <br />
                      <PieChart
                        width={350}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.survivors}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.survivors.map((entry, index) => (
                            <Cell
                              fill={COLORSTHREE[index % COLORSTHREE.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ marginLeft: "0em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Gender</h4>
                      <p>Number of incidents per gender.</p>
                      <br />
                      <PieChart
                        width={350}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.genderGroups}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.genderGroups.map((entry, index) => (
                            <Cell fill={COLORSTWO[index % COLORSTWO.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ marginRight: "0em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Housing</h4>
                      <p>Does patient have housing?</p>
                      <PieChart
                        width={300}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                        style={{ marginTop: "2.5em" }}
                      >
                        <Pie
                          data={this.props.housingList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.housingList.map((entry, index) => (
                            <Cell
                              fill={COLORSFOUR[index % COLORSFOUR.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{
                      marginRight: "1em",
                      marginLeft: "1em",
                      height: "25em",
                    }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Hunger</h4>
                      <p>Food Accessibile?</p>
                      <br />
                      <PieChart
                        width={300}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.hungerList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.hungerList.map((entry, index) => (
                            <Cell fill={COLORSTWO[index % COLORSTWO.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ marginLeft: "0em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Transportation</h4>
                      <p>Patient Transportation?</p>
                      <br />
                      <PieChart
                        width={300}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.transportationList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.transportationList.map((entry, index) => (
                            <Cell
                              fill={COLORSFOUR[index % COLORSFOUR.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "35em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Age</h4>
                      <p>The number of incidents by age.</p>
                      <br />
                      <AreaChart
                        width={1200}
                        height={400}
                        data={this.props.ageGroups}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label value="Age" position="bottom" offset={0} />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="amt"
                          stroke="#E6492D"
                          fill="#F6AB2F"
                        />
                      </AreaChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "35em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Race</h4>
                      <p>The number of incidents that occurred by race.</p>
                      <br />
                      <BarChart
                        width={1200}
                        height={350}
                        data={this.props.raceGroups}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 20,
                          bottom: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label value="Race" position="bottom" offset={0} />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Bar dataKey="value" fill="#3E3F42">
                          {this.props.raceGroups.map((entry, index) => (
                            <Cell fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ marginRight: "1em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Milligrams of Naloxone</h4>
                      <p>Milligrams of Naloxone used per incident </p>
                      <AreaChart
                        width={550}
                        height={200}
                        data={this.props.mgNaloxoneList}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label
                            value="Milligrams"
                            position="bottom"
                            offset={0}
                          />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="value"
                          stroke="#E6492D"
                          fill="#FF8042"
                        />
                      </AreaChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ marginLeft: "1em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Method of Overdose</h4>
                      <p>Incidents by methods of overdosage.</p>
                      <br />
                      <BarChart
                        width={500}
                        height={250}
                        data={this.props.overdoseList}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 20,
                          bottom: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label value="Race" position="bottom" offset={0} />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Bar dataKey="value" fill="#FACF55">
                          {this.props.overdoseList.map((entry, index) => (
                            <Cell
                              fill={COLORSFOUR[index % COLORSFOUR.length]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ marginRight: "0em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Intent</h4>
                      <p>The number of incidents by usage intent.</p>
                      <PieChart
                        width={350}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                        style={{ marginTop: "2.5em" }}
                      >
                        <Pie
                          data={this.props.intentList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.intentList.map((entry, index) => (
                            <Cell
                              fill={COLORSTHREE[index % COLORSTHREE.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{
                      marginRight: "1em",
                      marginLeft: "1em",
                      height: "25em",
                    }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Disposition</h4>
                      <p>Patient's disposition per incident.</p>
                      <br />
                      <PieChart
                        width={350}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.dispositionList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.dispositionList.map((entry, index) => (
                            <Cell fill={COLORSTWO[index % COLORSTWO.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    id="broken-div"
                    className="row justify-content-md-left wrapper--custom card--wrapper overflow-auto"
                    style={{
                      marginLeft: "0em",
                      height: "25em",
                      overflow: "scroll !important",
                    }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">List of Comments</h4>
                      <br />
                      {this.getComments()}
                      <br />
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </Desktop>
            <Laptop>
              <AppSidebar open activeItem="home" />
              <AppHeader activeItem="analytics" showSidebarMargins={false} />
              <div className="main-container no--sidebar">
                <div className="container-fluid" style={{ paddingTop: "2em" }}>
                  {uploadSuccess ? (
                    <div className="row justify-content-md-left">
                      <div
                        className="col-12 card--title"
                        style={{ marginTop: "1em" }}
                      >
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginBottom: "0em" }}
                        >
                          Successfully uploaded patient data.
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {updateSuccess ? (
                    <div className="row justify-content-md-left">
                      <div
                        className="col-12 card--title"
                        style={{ marginTop: "1em" }}
                      >
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginBottom: "0em" }}
                        >
                          Successfully updated user profile
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {inviteSuccess ? (
                    <div className="row justify-content-md-left">
                      <div
                        className="col-12 card--title"
                        style={{ marginTop: "1em" }}
                      >
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginBottom: "0em" }}
                        >
                          Successfully invited user.
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {updatePatientSuccess ? (
                    <div className="row justify-content-md-left">
                      <div
                        className="col-12 card--title"
                        style={{ marginTop: "1em" }}
                      >
                        <div
                          className="alert alert-success"
                          role="alert"
                          style={{ marginBottom: "0em" }}
                        >
                          Successfully updated patient data.
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className="row justify-content-md-left"
                    style={{ marginBottom: "1em" }}
                  >
                    <div className="col-12 card--title">
                      <h2 className="w-500">
                        Data Analytics
                        <span className="last-updated-subtitle w-200">
                          An overview of incidents reported
                        </span>
                      </h2>
                    </div>
                    <div className="col-6 card--title">
                      <h5>Select Hospital</h5>
                      <select
                        value={this.state.hospitalSelect}
                        defaultValue="all"
                        onChange={this.onHospitalSelect}
                        name="hospitalName"
                        className="browser-default custom-select"
                        required
                      >
                        <option value="all">All Hospitals</option>
                        <option value="St. Luke's Cornwall Hospital">
                          St. Luke's Cornwall Hospital
                        </option>
                        <option value="Orange Regional Medical Center">
                          Orange Regional Medical Center
                        </option>
                        <option value="Catskill Regional Medical Center">
                          Catskill Regional Medical Center
                        </option>
                        <option value="Ellenville Hospital">
                          Ellenville Hospital
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "40em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incident Overview By Hospital</h4>
                      <p>
                        The number of incidents per hospital.
                        <strong>PV</strong> is the point value number of
                        incidents and
                        <strong>rate</strong> represents the percentage of the
                        total incidents.
                      </p>
                      <PieChart
                        width={1000}
                        height={500}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.hospitalIncidents}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={170}
                        >
                          {this.props.hospitalIncidents.map((entry, index) => (
                            <Cell fill={COLORSTWO[index % COLORSTWO.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "40em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Zipcodes</h4>
                      <p>
                        The number of incidents that occurred by a specific
                        zipcode.
                      </p>
                      <br />
                      <BarChart
                        width={1000}
                        height={350}
                        data={this.props.zipCodes}
                        margin={{
                          top: 55,
                          right: 30,
                          left: 20,
                          bottom: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label
                            value="Zipcodes"
                            position="bottom"
                            offset={0}
                          />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Bar dataKey="incidents" fill="#34AA44">
                          {this.props.zipCodes.map((entry, index) => (
                            <Cell fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ width: "40%", height: "25em", marginRight: "1em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents per County</h4>
                      <p>The number of incidents by county region.</p>
                      <BarChart
                        width={400}
                        height={250}
                        data={this.props.countyIncidents}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 10,
                          bottom: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label value="County" position="bottom" offset={0} />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Bar dataKey="incidents" fill="#E6492D">
                          {this.props.countyIncidents.map((entry, index) => (
                            <Cell fill={COLORSTWO[index % COLORSTWO.length]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Survival Rate</h4>
                      <p>Did patient survive?</p>
                      <br />
                      <PieChart
                        width={400}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.survivors}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.survivors.map((entry, index) => (
                            <Cell
                              fill={COLORSTHREE[index % COLORSTHREE.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ width: "40%", height: "25em", marginRight: "1em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by gender</h4>
                      <p>Number of incidents per gender.</p>
                      <br />
                      <PieChart
                        width={400}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.genderGroups}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.genderGroups.map((entry, index) => (
                            <Cell fill={COLORSTWO[index % COLORSTWO.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Housing</h4>
                      <p>Does patient have housing?</p>
                      <br />
                      <PieChart
                        width={400}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.housingList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.housingList.map((entry, index) => (
                            <Cell
                              fill={COLORSFOUR[index % COLORSFOUR.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ width: "40%", height: "25em", marginRight: "1em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Transportation</h4>
                      <p>Does Patient Have Transportation</p>
                      <br />
                      <PieChart
                        width={400}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.transportationList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.transportationList.map((entry, index) => (
                            <Cell
                              fill={COLORSFOUR[index % COLORSFOUR.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Hunger</h4>
                      <p>Food Accessible?</p>
                      <br />
                      <PieChart
                        width={400}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.hungerList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.hungerList.map((entry, index) => (
                            <Cell fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "35em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Age</h4>
                      <p>The number of incidents by age.</p>
                      <br />
                      <AreaChart
                        width={1000}
                        height={400}
                        data={this.props.ageGroups}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label value="Age" position="bottom" offset={0} />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="amt"
                          stroke="#E6492D"
                          fill="#F6AB2F"
                        />
                      </AreaChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "35em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Race</h4>
                      <p>The number of incidents that occurred by race.</p>
                      <br />
                      <BarChart
                        width={1000}
                        height={350}
                        data={this.props.raceGroups}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 20,
                          bottom: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label value="Race" position="bottom" offset={0} />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Bar dataKey="value" fill="#3E3F42">
                          {this.props.raceGroups.map((entry, index) => (
                            <Cell fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ marginRight: "1em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Milligrams of Naloxone</h4>
                      <p>Milligrams of Naloxone used per incident </p>
                      <AreaChart
                        width={400}
                        height={200}
                        data={this.props.mgNaloxoneList}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label
                            value="Milligrams"
                            position="bottom"
                            offset={0}
                          />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="value"
                          stroke="#E6492D"
                          fill="#FF8042"
                        />
                      </AreaChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ marginLeft: "1em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Method of Overdose</h4>
                      <p>Incidents by methods of overdosage.</p>
                      <br />
                      <BarChart
                        width={550}
                        height={250}
                        data={this.props.overdoseList}
                        margin={{
                          top: 15,
                          right: 30,
                          left: 20,
                          bottom: 30,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                          <Label value="Race" position="bottom" offset={0} />
                        </XAxis>
                        <YAxis>
                          <Label
                            value="Incidents"
                            angle={-90}
                            position="insideLeft"
                            offset={0}
                          />
                        </YAxis>
                        <Tooltip />
                        <Bar dataKey="value" fill="#FACF55">
                          {this.props.overdoseList.map((entry, index) => (
                            <Cell
                              fill={COLORSFOUR[index % COLORSFOUR.length]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper float-left"
                    style={{ width: "40%", marginRight: "1em", height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Incidents by Intent</h4>
                      <p>The number of incidents by usage intent.</p>
                      <PieChart
                        width={400}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                        style={{ marginTop: "3em" }}
                      >
                        <Pie
                          data={this.props.intentList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.intentList.map((entry, index) => (
                            <Cell fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "25em" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">Patient Disposition</h4>
                      <p>Patient's disposition per incident.</p>
                      <br />
                      <PieChart
                        width={335}
                        height={200}
                        onMouseEnter={this.onPieEnter}
                      >
                        <Pie
                          data={this.props.dispositionList}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={80}
                          fill="#8884d8"
                        >
                          {this.props.dispositionList.map((entry, index) => (
                            <Cell fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </div>
                  </div>
                  <br />
                  <div
                    className="row justify-content-md-left wrapper--custom card--wrapper"
                    style={{ height: "25em", overflow: "scroll" }}
                  >
                    <div className="col-12" style={{ marginTop: "1em" }}>
                      <h4 className="w-500">List of Comments</h4>
                      <br />
                      {this.getComments()}
                      <br />
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </Laptop>
          </div>
        )}
      </div>
    );
  }
}

// ==========================================================
// CONNECT
// ----------------------------------------------------------

const mapStateToProps = (state) => ({
  zipCodes: loadZipcodes(state.analytics),
  hospitalIncidents: loadHospitals(state.analytics),
  countyIncidents: loadCounty(state.analytics),
  survivors: loadSurvivors(state.analytics),
  ageGroups: loadAge(state.analytics),
  genderGroups: loadGender(state.analytics),
  raceGroups: loadEthnicity(state.analytics),
  overdoseList: loadOverdoses(state.analytics),
  intentList: loadIntent(state.analytics),
  arrivalTimeList: loadArrivalTime(state.analytics),
  dispositionList: loadDisposition(state.analytics),
  commentsList: loadComments(state.analytics),
  mgNaloxoneList: loadMGNaloxone(state.analytics),
  hungerList: loadHunger(state.analytics),
  housingList: loadHousing(state.analytics),
  transportationList: loadTransportation(state.analytics),
});

const mapDispatchToProps = (dispatch) => ({
  setPatientData: (patientData) =>
    dispatch(analyticsActions.setPatientData(patientData)),
  setHospitalSelect: (hospital) =>
    dispatch(analyticsActions.setHospitalSelect(hospital)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsPage);
