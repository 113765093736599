import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "mdbreact";
import { Link } from "react-router-dom";

import "./app-header.scss";

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: props.activeItem,
      showSidebarMargins: props.showSidebarMargins
    };
  }

  onSignOut = e => {
    const url = process.env.REACT_APP_LOGOUT_URL;
    localStorage.clear();
    window.location.replace(
      `https://hvias.auth0.com/v2/logout?returnTo=${url}`
    );
  };

  activeNavBarItem = () => {
    const is_admin = this.props.user.app_metadata
      ? this.props.user.app_metadata.admin
      : false;
    const activeItem = this.state.activeItem;
    if (activeItem === "home") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item_active">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
    if (activeItem === "uploadreports") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
    if (activeItem === "editdata") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item_active">Edit Data</span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
    if (activeItem === "myreports") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item_active">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
    if (activeItem === "editreports") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
        </ul>
      );
    }
    if (activeItem === "privacyPolicy") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item_active">
              <Link to="/privacypolicy">Privacy Policy</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
    if (activeItem === "termsAndConditions") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item_active">
              <Link to="/termsandconditions">Terms And Conditions</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
    if (activeItem === "account") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item_active">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
    if (activeItem === "analytics") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item_active">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
    if (activeItem === "inviteUser") {
      return (
        <ul>
          <li>
            <span className="navigation_bar--item">
              <Link to="/uploadData">Enter Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/viewData">View Data</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/analytics">Analytics</Link>
            </span>
          </li>
          <li>
            <span className="navigation_bar--item">
              <Link to="/updateProfile">Predx</Link>
            </span>
          </li>
          {is_admin ? (
            <li>
              <span className="navigation_bar--item_active">
                <Link to="/inviteUser">Invite User</Link>
              </span>
            </li>
          ) : null}
        </ul>
      );
    }
  };

  getHospitalName = () => {
    const userMetaData = this.props.user.user_metadata;
    if (userMetaData) {
      return userMetaData.hospital;
    }
  };

  getName = () => {
    const userMetaData = this.props.user.user_metadata;
    if (userMetaData && userMetaData.name) {
      return `Hello, ${userMetaData.name}`;
    }
    return "Welcome to HVIAS!";
  };

  activeSidebar = () => {
    const showSidebarMargins = this.state.showSidebarMargins;
    if (showSidebarMargins) {
      return (
        <div className="app-header">
          <div className="container-fluid">
            <div
              className="row justify-content-md-left logo--wrapper_margins"
              style={{ background: "#fff" }}
            >
              <div className="col-8 logo--wrapper">
                <p className="logo--text" style={{ marginBottom: "1.5em" }}>
                  {this.getName()}
                  <span className="title-subtitle">
                    {this.getHospitalName()}
                  </span>
                </p>
              </div>
              <div className="col-4 text-right" style={{ paddingRight: "0" }}>
                <br />
                <Button
                  class="btn btn-warning btn-sm text-dark"
                  size="sm"
                  onClick={this.onSignOut}
                >
                  PeerRX
                </Button>
                <Button color="elegant" size="sm">
                  Uber
                </Button>
                <Button color="elegant" size="sm" onClick={this.onSignOut}>
                  Log out
                </Button>
              </div>
              <div className="col-12 logo--wrapper navigation_bar">
                {this.activeNavBarItem()}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="app-header no--sidebar">
        <div className="container-fluid">
          <div
            className="row justify-content-md-left logo--wrapper_margins"
            style={{ background: "#fff" }}
          >
            <div className="col-8 logo--wrapper">
              <p className="logo--text" style={{ marginBottom: "1.5em" }}>
                {this.getName()}
                <span className="title-subtitle">{this.getHospitalName()}</span>
              </p>
            </div>
            <div className="col-4 text-right" style={{ paddingRight: "0" }}>
              <Button size="sm" onClick={this.onSignOut}>
                PeerRX
              </Button>
              <Button color="elegant" size="sm">
                Uber
              </Button>
              <Button color="elegant" size="sm" onClick={this.onSignOut}>
                Log out
              </Button>
            </div>
            <div className="col-12 logo--wrapper navigation_bar">
              {this.activeNavBarItem()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <div>{this.activeSidebar()}</div>;
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

// ==========================================================
// CONNECT
// ----------------------------------------------------------

export default connect(mapStateToProps, null)(AppHeader);
