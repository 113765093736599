import React, { Component } from 'react';
import Sidebar from '../../components/app-sidebar';
import './contact-page.scss';

class ContactPage extends Component {
  render() {
    return (
      <div>
        <Sidebar />
        <div className="container contact_us_container">
          <div className="row contact_us_row">
            <div className="col-4 contact_us_col">
              <div>
                <div><i className="fa fa-envelope fa-4x" aria-hidden="true" /></div>
                <div>Email Us At</div>
                <div><a href="mailto:support@1life.io">support@1life.io</a></div>
              </div>
            </div>
            <div className="col-4 contact_us_col">
              <div>
                <div><i className="fa fa-phone fa-4x" aria-hidden="true" /></div>
                <div>Call Us At</div>
                <div>(917)-999-9999</div>
              </div>
            </div>
            <div className="col-4 contact_us_col">
              <div>
                <div><i className="fa fa-link fa-4x" aria-hidden="true" /></div>
                <div>Find Us Online At</div>
                <div><a href="//www.1life.io">www.1life.io</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;
