import React, { PureComponent } from 'react';
import Responsive from 'react-responsive';

import AppHeader from '../../components/app-header';
import AppSidebar from '../../components/app-sidebar';
import Footer from '../../components/app-footer';
import TermsAndConditions from '../../components/terms-and-conditions';

const Desktop = props => <Responsive {...props} minWidth={1500} />;
const Laptop = props => <Responsive {...props} maxWidth={1499} />;

export default class TermsAndConditionsPage extends PureComponent {
  render() {
    return (
      <div>
        <Desktop>
          <AppSidebar open={false} activeItem="home" />
          <AppHeader activeItem="termsAndConditions" showSidebarMargins />
          <div className="main-container">
            <div className="container-fluid">
              <TermsAndConditions />
            </div>
            <Footer />
          </div>
        </Desktop>

        <Laptop>
          <AppSidebar open activeItem="home" />
          <AppHeader activeItem="termsAndConditions" showSidebarMargins={false} />
          <div className="main-container no--sidebar">
            <div className="container-fluid">
              <TermsAndConditions />
            </div>
            <Footer />
          </div>
        </Laptop>
      </div>
    );
  }
}
