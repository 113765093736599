import React, { PureComponent } from 'react';
import Responsive from 'react-responsive';

import AppHeader from '../../components/app-header';
import AppSidebar from '../../components/app-sidebar';
import Footer from '../../components/app-footer';
import './single-patient-page.scss';

const Desktop = props => <Responsive {...props} minWidth={1500} />;
const Laptop = props => <Responsive {...props} maxWidth={1499} />;

export default class HomePage extends PureComponent {
  render() {
    return (
      <div>
        <Desktop>
          <AppSidebar open={false} activeItem="home" />
          <AppHeader activeItem="home" showSidebarMargins />
          <div className="main-container">
            <div className="container-fluid">
              <div className="row justify-content-md-left">
                <div className="col-12 card--title" style={{ marginTop: '2em' }}>
                  <h2 className="w-500">
                    Incident Description
                    <span className="last-updated-subtitle w-200">
                      ID:
                      {' '}
                      {this.props.location.state.patientInfo._id}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12 col--top_margin">
                  <ul>
                    <li>
                      <strong>Unique ID:</strong>
                      {' '}
                      {this.props.location.state.patientInfo._id}
                    </li>
                    <li>
                      <strong>Created At:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.createdAt}
                    </li>
                    <li>
                      <strong>Hospital Name:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.incidentDescription.hospitalName}
                    </li>
                    <li>
                      <strong>Overdose Date:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.incidentDescription.overdoseDate}
                    </li>
                    <li>
                      <strong>EMS Arrival Time:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.incidentDescription.emsArrivalTime}
                    </li>
                    <li>
                      <strong>Hospital Arrival Time:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.incidentDescription.hospitalArrivalTime}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row justify-content-md-left">
                <div className="col-12 card--title" style={{ marginTop: '1em' }}>
                  <h2 className="w-500">
                    Patient Information
                    <span className="last-updated-subtitle w-200" />
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12 col--top_margin">
                  <ul>
                    <li>
                      <strong>Gender:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.gender}
                    </li>
                    <li>
                      <strong>Race:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.race}
                    </li>
                    <li>
                      <strong>Age:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.age}
                    </li>
                    <li>
                      <strong>Housing:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.hunger}
                    </li>
                    <li>
                      <strong>Transportation:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.hunger}
                    </li>
                    <li>
                      <strong>Hunger:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.hunger}
                    </li>
                    <li>
                      <strong>Veteran:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.isVeteran}
                    </li>
                    <li>
                      <strong>Cause of Overdose:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.causeOfOverdose}
                    </li>
                    <li>
                      <strong>Was Naloxone administerd by anyone else?:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.naloxoneAdministeredAnyoneElse}
                    </li>
                    <li>
                      <strong>County of Overdose:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.countyOfOverdose}
                    </li>
                    <li>
                      <strong>If Other County:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.countyOfOverdoseOther}
                    </li>
                    <li>
                      <strong>Zip Code of Overdose:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.zipCode}
                    </li>
                    <li>
                      <strong>Zip Code of Residence:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.zipCodeResidence}
                    </li>
                    <li>
                      <strong>Responsiveness:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.responsiveness}
                    </li>
                    <li>
                      <strong>Breathing:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.breathing}
                    </li>
                    <li>
                      <strong>Pulse:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.pulse}
                    </li>
                    <li>
                      <strong>Did the person survive?:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.didPersonSurvive}
                    </li>
                    <li>
                      <strong>Comment/Package or Paraphernalia Description:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.paraphernaliDescription}
                    </li>
                    <li>
                      <strong>How many miligrams of Naloxone:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.milligramsNaloxone}
                    </li>
                    <li>
                      <strong>How was Naloxone administered:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.naloxoneAdministeredAnyoneElse}
                    </li>
                    <li>
                      <strong>Method of Overdose:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.naloxoneAdministered}
                    </li>
                    <li>
                      <strong>Intent:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.methodOverdose}
                    </li>
                    <li>
                      <strong>Transporting Ambulance:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.intentOfUse}
                    </li>
                    <li>
                      <strong>Disposition of Patient:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.dispositionOfPatient}
                    </li>
                    <li>
                      <strong>Comments Relating to Hospital Transfer:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.commentsOnHospitalTransfer}
                    </li>
                    <li>
                      <strong>Any other comments:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.otherComments}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row justify-content-md-left">
                <div className="col-12 card--title" style={{ marginTop: '1em' }}>
                  <h2 className="w-500">
                    Police Information
                    <span className="last-updated-subtitle w-200" />
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12 col--top_margin">
                  <ul>
                    <li>
                      <strong>Police Description:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.description}
                    </li>
                    <li>
                      <strong>Police Arrival Time:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.arrivalTime}
                    </li>
                    <li>
                      <strong>Officer on scene:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.arrivalTime}
                    </li>
                    <li>
                      <strong>Shield number:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.shieldNumber}
                    </li>
                    <li>
                      <strong>Case number:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.caseNumber}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Desktop>

        <Laptop>
          <AppSidebar open activeItem="home" />
          <AppHeader activeItem="home" showSidebarMargins={false} />
          <div className="main-container no--sidebar">
            <div className="container-fluid">
              <div className="row justify-content-md-left">
                <div className="col-12 card--title" style={{ marginTop: '1em' }}>
                  <h2 className="w-500">
                    Incident Description
                    <span className="last-updated-subtitle w-200">
                      ID:
                      {' '}
                      {this.props.location.state.patientInfo._id}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12 col--top_margin">
                  <ul>
                    <li>
                      <strong>Unique ID:</strong>
                      {' '}
                      {this.props.location.state.patientInfo._id}
                    </li>
                    <li>
                      <strong>Created At:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.createdAt}
                    </li>
                    <li>
                      <strong>Hospital Name:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.incidentDescription.hospitalName}
                    </li>
                    <li>
                      <strong>Overdose Date:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.incidentDescription.overdoseDate}
                    </li>
                    <li>
                      <strong>EMS Arrival Time:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.incidentDescription.emsArrivalTime}
                    </li>
                    <li>
                      <strong>Hospital Arrival Time:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.incidentDescription.hospitalArrivalTime}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row justify-content-md-left">
                <div className="col-12 card--title" style={{ marginTop: '1em' }}>
                  <h2 className="w-500">
                    Patient Information
                    <span className="last-updated-subtitle w-200" />
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12 col--top_margin">
                  <ul>
                    <li>
                      <strong>Gender:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.gender}
                    </li>
                    <li>
                      <strong>Race:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.race}
                    </li>
                    <li>
                      <strong>Age:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.age}
                    </li>
                    <li>
                      <strong>Housing:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.hunger}
                    </li>
                    <li>
                      <strong>Transportation:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.hunger}
                    </li>
                    <li>
                      <strong>Hunger:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.hunger}
                    </li>
                    <li>
                      <strong>Veteran:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.isVeteran}
                    </li>
                    <li>
                      <strong>Cause of Overdose:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.causeOfOverdose}
                    </li>
                    <li>
                      <strong>Was Naloxone administerd by anyone else?:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.naloxoneAdministeredAnyoneElse}
                    </li>
                    <li>
                      <strong>County of Overdose:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.countyOfOverdose}
                    </li>
                    <li>
                      <strong>If Other County:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.countyOfOverdoseOther}
                    </li>
                    <li>
                      <strong>Zip Code of Overdose:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.zipCode}
                    </li>
                    <li>
                      <strong>Zip Code of Residence:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.zipCodeResidence}
                    </li>
                    <li>
                      <strong>Responsiveness:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.responsiveness}
                    </li>
                    <li>
                      <strong>Breathing:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.breathing}
                    </li>
                    <li>
                      <strong>Pulse:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.pulse}
                    </li>
                    <li>
                      <strong>Did the person survive?:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.didPersonSurvive}
                    </li>
                    <li>
                      <strong>Comment/Package or Paraphernalia Description:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.paraphernaliDescription}
                    </li>
                    <li>
                      <strong>How many miligrams of Naloxone:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.milligramsNaloxone}
                    </li>
                    <li>
                      <strong>How was Naloxone administered:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.naloxoneAdministeredAnyoneElse}
                    </li>
                    <li>
                      <strong>Method of Overdose:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.naloxoneAdministered}
                    </li>
                    <li>
                      <strong>Intent:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.methodOverdose}
                    </li>
                    <li>
                      <strong>Transporting Ambulance:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.intentOfUse}
                    </li>
                    <li>
                      <strong>Disposition of Patient:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.dispositionOfPatient}
                    </li>
                    <li>
                      <strong>Comments Relating to Hospital Transfer:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.commentsOnHospitalTransfer}
                    </li>
                    <li>
                      <strong>Any other comments:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.patientInformation.otherComments}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row justify-content-md-left">
                <div className="col-12 card--title" style={{ marginTop: '1em' }}>
                  <h2 className="w-500">
                    Police Information
                    <span className="last-updated-subtitle w-200" />
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12 col--top_margin">
                  <ul>
                    <li>
                      <strong>Police Description:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.description}
                    </li>
                    <li>
                      <strong>Police Arrival Time:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.arrivalTime}
                    </li>
                    <li>
                      <strong>Officer on scene:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.arrivalTime}
                    </li>
                    <li>
                      <strong>Shield number:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.shieldNumber}
                    </li>
                    <li>
                      <strong>Case number:</strong>
                      {' '}
                      {this.props.location.state.patientInfo.policeInformation.caseNumber}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Laptop>

      </div>
    );
  }
}
