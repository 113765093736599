import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Responsive from "react-responsive";
import ReportsTable from "../../components/reports-table";
import SearchBar from "../../components/search-bar";
import AppHeader from "../../components/app-header";
import AppSidebar from "../../components/app-sidebar";
import Footer from "../../components/app-footer";

const now = moment();
const Desktop = props => <Responsive {...props} minWidth={1500} />;
const Laptop = props => <Responsive {...props} maxWidth={1499} />;

export class DataOverview extends Component {
  render() {
    return (
      <div>
        <Desktop>
          <AppSidebar open={false} activeItem="home" />
          <AppHeader activeItem="home" showSidebarMargins />
          <div className="main-container">
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              <div
                className="row justify-content-md-left"
                style={{ marginBottom: "1em" }}
              >
                <div className="col-12 card--title">
                  <h2 className="w-500">
                    Patient Data Overview
                    <span className="last-updated-subtitle w-200">
                      {now.format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-9">
                  <SearchBar
                    handleSearch={this.props.handleSearch}
                    search={this.props.search}
                  />
                </div>
                <div className="col-12">
                  <ReportsTable />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Desktop>
        <Laptop>
          <AppSidebar open activeItem="home" />
          <AppHeader activeItem="home" showSidebarMargins={false} />
          <div className="main-container no--sidebar">
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              <div className="row justify-content-md-left">
                <div className="col-12 card--title">
                  <h2 className="w-500">
                    Patient Data Overview
                    <span className="last-updated-subtitle w-200">
                      {now.format("dddd, MMMM Do YYYY, h:mm:ss a")}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-9">
                  <SearchBar
                    handleSearch={this.props.handleSearch}
                    search={this.props.search}
                  />
                </div>
                <div className="col-12">
                  <ReportsTable />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Laptop>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedReports: state.reports.selectedReports
});

// ==========================================================
// CONNECT
// ----------------------------------------------------------

export default connect(
  mapStateToProps,
  null
)(DataOverview);
