import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  FormInline,
  Fa,
} from 'mdbreact';
import { searchActions } from '../../../core/search';


class SearchBar extends PureComponent {
  static propTypes = {
    setTextFilter: PropTypes.func.isRequired,
  };

  onTextChange = (e) => {
    this.props.setTextFilter(e.target.value);
  }

  render() {
    return (
      <div role="search">
        <FormInline className="md-form" onSubmit={this.handleSubmit} noValidate>
          <Fa icon="search" />
          <input
            className="form-control form-control-sm form--search ml-3 w-100"
            type="text"
            placeholder="Search by Hospital"
            value={this.props.textFilter}
            onChange={this.onTextChange}
            aria-label="Search"
          />
        </FormInline>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  textFilter: state.search.textFilter,
});

const mapDispatchToProps = dispatch => ({
  setTextFilter: text => dispatch(searchActions.setTextFilter(text)),
});

// ==========================================================
// CONNECT
// ----------------------------------------------------------

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchBar);
