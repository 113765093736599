import React, { Component } from "react";
import { connect } from "react-redux";
import Responsive from "react-responsive";

import AnalyticsPage from "../analytics-page";
import UploadDataPage from "../upload-data-page";
import "./home-page.scss";

const Desktop = (props) => <Responsive {...props} minWidth={1500} />;
const Laptop = (props) => <Responsive {...props} maxWidth={1499} />;

class HomePage extends Component {
  render() {
    const isAdmin = this.props.user.app_metadata
      ? this.props.user.app_metadata.admin
      : true;
    return (
      <div>
        <Desktop>
          {isAdmin ? (
            <AnalyticsPage location={this.props.location} />
          ) : (
            <UploadDataPage location={this.props.location} />
          )}
        </Desktop>
        <Laptop>
          {isAdmin ? (
            <AnalyticsPage location={this.props.location} />
          ) : (
            <UploadDataPage location={this.props.location} />
          )}
        </Laptop>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

// ==========================================================
// CONNECT
// ----------------------------------------------------------

export default connect(mapStateToProps, null)(HomePage);
