import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { Button, Input, Modal, ModalBody } from "mdbreact";
import Responsive from "react-responsive";

import "./register-page.scss";

const Desktop = props => <Responsive {...props} minWidth={992} />;
const Tablet = props => <Responsive {...props} maxWidth={991} />;

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAndConditions: false,
      username: "",
      password: "",
      message: ""
    };
  }

  toggle = () => {
    this.setState(prevState => {
      const termsAndConditionsCopy = !prevState.termsAndConditions;
      return { termsAndConditions: termsAndConditionsCopy };
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;

    axios
      .post(process.env.REACT_APP_API_URL + "api/auth/register", {
        username,
        password
      })
      .then(result => {
        this.props.history.push("/login");
      });
  };

  render() {
    const { termsAndConditions, username, password, message } = this.state;
    return (
      <div className="container-fluid">
        <div className="row full-height">
          <Desktop>
            <div className="col-4 align-self-center register">
              <div className="register_input--container">
                <div className="register_logo text-center">
                  <h2 className="register_logo--title">Request an Account</h2>
                </div>
                <div className="row">
                  <div className="col-6 align-self-center">
                    <Input label="First Name" size="md" icon="genderless" />
                  </div>
                  <div className="col-6 align-self-center">
                    <Input label="Last Name" size="md" icon="genderless" />
                  </div>
                  <div
                    className="col-12 align-self-center"
                    style={{ marginTop: "-1.5rem" }}
                  >
                    {" "}
                    {/* Margin Top -1.5 rem to counter MDB component nested margins */}
                    <form onSubmit={this.onSubmit}>
                      {message !== "" && <div>{message}</div>}
                      <Input
                        label="Email Address"
                        icon="user"
                        size="md"
                        type="email"
                        value={username}
                        name="username"
                        onChange={this.onChange}
                        required
                      />
                      <Input
                        label="Password"
                        icon="lock"
                        size="md"
                        type="password"
                        value={password}
                        name="password"
                        onChange={this.onChange}
                        required
                      />
                      <Button
                        className="btn--login"
                        size="md"
                        color="elegant"
                        type="submit"
                        rounded
                      >
                        Register
                      </Button>
                    </form>
                  </div>
                </div>
                <div className="register_text--bottom">
                  <p>
                    Already have an account?
                    <Link to="/login">&nbsp;Log in here </Link>
                  </p>
                </div>
                <div className="col-4 register_copyright">
                  <p>
                    &copy;2018 1Life Project |
                    <span
                      className="hyperlink--text"
                      onClick={this.toggle}
                      role="none"
                    >
                      &nbsp;Terms and Conditions
                    </span>{" "}
                    |
                    <span className="hyperlink--text">
                      &nbsp;Privacy Policy
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-8 col-8--background align-self-center">
              <h1 className="main_title">
                Register For An
                <span className="weight--800">&nbsp;Account</span>
              </h1>
              <p className="main_subtitle">
                Request an account for access to our tools
              </p>
            </div>
          </Desktop>

          <Tablet>
            <div className="col-12 align-self-center register">
              <div className="register_input--container">
                <div className="register_logo text-center">
                  <h2 className="register_logo--title">Request an Account</h2>
                </div>
                <div className="row">
                  <div className="col-6 align-self-center">
                    <Input label="First" size="md" icon="genderless" />
                  </div>
                  <div className="col-6 align-self-center">
                    <Input label="Last" size="md" icon="genderless" />
                  </div>
                  <div
                    className="col-12 align-self-center"
                    style={{ marginTop: "-1.5rem" }}
                  >
                    {" "}
                    {/* Margin Top -1.5 rem to counter MDB component nested margins */}
                    <Input label="Email Address" icon="user" />
                    <Input
                      label="Password"
                      icon="lock"
                      size="md"
                      type="password"
                    />
                    <Input
                      label="Confirm Password"
                      icon="lock"
                      size="md"
                      type="password"
                    />
                    <Button
                      className="btn--request"
                      size="md"
                      color="elegant"
                      rounded
                    >
                      {" "}
                      Send Request
                    </Button>
                  </div>
                </div>
                <div className="register_text--bottom">
                  <p>
                    Already have an account?
                    <Link to="/login">&nbsp;Log in here </Link>
                  </p>
                </div>
                <div className="col-12 register_copyright">
                  <p>
                    &copy;2018 1Life Project |
                    <span
                      className="hyperlink--text"
                      onClick={this.toggle}
                      role="none"
                    >
                      &nbsp;Terms and Conditions
                    </span>{" "}
                    |
                    <span className="hyperlink--text">
                      &nbsp;Privacy Policy
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Tablet>
        </div>

        {/* Modal For Terms And Conditions */}
        <Modal
          className="modal_wrapper"
          isOpen={termsAndConditions}
          toggle={() => this.toggle()}
          fullHeight
          position="right"
          size="md"
        >
          <ModalBody className="modal_terms--text">
            <h5>Introduction</h5>
            <p>
              These Website Standard Terms and Conditions written on this
              webpage shall manage your use of our website, 1Life Project
              accessible at 1Life Project.com. These Terms will be applied fully
              and affect to your use of 1Life Project Web Application. By using
              1Life Project Web Application, you agreed to accept all terms and
              conditions written in here. You must not use 1Life Project Web
              Application if you disagree with any of these Website Standard
              Terms and Conditions. Minors or people below 18 years old are not
              allowed to use 1Life Project Web Application.
            </p>
            <h5>Intellectual Property Rights</h5>
            <p>
              Other than the content you own, under these Terms, 1Life Project
              and/or its licensors own all the intellectual property rights and
              materials contained in 1Life Project Web Application. You are
              granted limited license only for purposes of viewing the material
              contained on 1Life Project Web Application.
            </p>
            <h5>Restrictions</h5>
            <p>You are specifically restricted from all of the following:</p>
            <ul>
              <li>publishing any Website material in any other media;</li>
              <li>
                selling, sublicensing and/or otherwise commercializing any
                Website material;
              </li>
              <li>publicly performing and/or showing any Website material;</li>
              <li>
                using 1Life Project Web Application in any way that is or may be
                damaging to 1Life Project Web Application;
              </li>
              <li>
                using 1Life Project Web Application in any way that impacts user
                access to 1Life Project Web Application;
              </li>
              <li>
                using 1Life Project Web Application contrary to applicable laws
                and regulations, or in any way may cause harm to the Website, or
                to any person or business entity;
              </li>
              <li>
                engaging in any data mining, data harvesting, data extracting or
                any other similar activity in relation to 1Life Project Web
                Application;
              </li>
              <li>
                using 1Life Project Web Application to engage in any advertising
                or marketing.
              </li>
            </ul>
            <p>
              Certain areas of 1Life Project Web Application are restricted from
              being access by you and 1Life Project may further restrict access
              by you to any areas of 1Life Project Web Application, at any time,
              in absolute discretion. Any user ID and password you may have for
              1Life Project Web Application are confidential and you must
              maintain confidentiality as well.
            </p>
            <h5>Your Content</h5>
            <p>
              In these Website Standard Terms and Conditions, “Your Content”
              shall mean any audio, video text, images or other material you
              choose to display on 1Life Project Web Application. By displaying
              Your Content, you grant 1Life Project a non-exclusive, worldwide
              irrevocable, sub licensable license to use, reproduce, adapt,
              publish, translate and distribute it in any and all media. Your
              Content must be your own and must not be invading any
              third-party&#39;s rights. 1Life Project reserves the right to
              remove any of Your Content from 1Life Project Web Application at
              any time without notice.
            </p>
            <h5>No Warranties</h5>
            <p>
              This Website is provided “as is,” with all faults, and 1Life
              Project express no representations or warranties, of any kind
              related to 1Life Project Web Application or the materials
              contained on 1Life Project Web Application. Also, nothing
              contained on 1Life Project Web Application shall be interpreted as
              advising you.
            </p>
            <h5>Limitation of Liability</h5>
            <p>
              In no event shall 1Life Project, nor any of its officers,
              directors and employees, shall be held liable for anything arising
              out of or in any way connected with your use of 1Life Project Web
              Application whether such liability is under contract. 1Life
              Project, including its officers, directors and employees shall not
              be held liable for any indirect, consequential or special
              liability arising out of or in any way related to your use of
              1Life Project Web Application.
            </p>
            <h5>Indemnification</h5>
            <p>
              You hereby indemnify to the fullest extent 1Life Project from and
              against any and/or all liabilities, costs, demands, causes of
              action, damages and expenses arising in any way related to your
              breach of any of the provisions of these Terms.
            </p>
            <h5>Severability</h5>
            <p>
              If any provision of these Terms is found to be invalid under any
              applicable law, such provisions shall be deleted without affecting
              the remaining provisions herein.
            </p>
            <h5>Variation of Terms</h5>
            <p>
              1Life Project is permitted to revise these Terms at any time as it
              sees fit, and by using 1Life Project Web Application you are
              expected to review these Terms on a regular basis.
            </p>
            <h5>Assignment</h5>
            <p>
              The 1Life Project is allowed to assign, transfer, and subcontract
              its rights and/or obligations under these Terms without any
              notification. However, you are not allowed to assign, transfer, or
              subcontract any of your rights and/or obligations under these
              Terms.
            </p>
            <h5>Entire Agreement</h5>
            <p>
              These Terms constitute the entire agreement between 1Life Project
              and you in relation to your use of 1Life Project Web Application,
              and supersede all prior agreements and understandings.
            </p>
            <h5>Governing Law and Jurisdiction</h5>
            <p>
              These Terms will be governed by and interpreted in accordance with
              the laws of the State of New York, and you submit to the
              non-exclusive jurisdiction of the state and federal courts located
              in USA for the resolution of any disputes.
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default connect(null, null)(RegisterPage);
