export const notificationActions = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',

  setNotifications: notificationData => ({
    type: notificationActions.SET_NOTIFICATIONS,
    payload: {
      notificationData,
    },
  }),
};
