import { combineReducers } from 'redux';
import { reportsReducer } from './reports';
import { searchReducer } from './search';
import { analyticsReducer } from './analytics';
import { userReducer } from './user';
import { notificationReducer } from './notifications';

export default combineReducers({
  reports: reportsReducer,
  search: searchReducer,
  analytics: analyticsReducer,
  user: userReducer,
  notification: notificationReducer,
});
