import { searchActions } from './actions';

const searchDefaultState = {
  textFilter: '',
};

export function searchReducer(state = searchDefaultState, { payload, type }) {
  switch (type) {
    case searchActions.SET_TEXT_FILTER:
      return {
        ...state,
        textFilter: payload.text,
      };
    default:
      return state;
  }
}
