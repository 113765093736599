import React from 'react';
import { connect } from 'react-redux';
import './notification-section.scss';
import { Component } from 'react';
import { loadReportsSelector } from '../../../core/reports';

class NotificationSection extends Component {
  constructor(props) {
    super(props);
    let userData = this.props.user.user_metadata;
    if (!userData) {
      userData = {};
    }
    const emptyList = this.checkPredx(userData);
    this.state = {
      predx: (emptyList.length === 0),
      missingList: emptyList,
      hospital: userData.hospital,
      zipCode: userData.zipCode,
      allZipCodes: userData.allZipCodes,
      predxAmount: userData.predxAmount,
      predxTime: userData.predxTime,
    };
  }

  checkPredx(userData) {
    const emptyList = [];
    console.log(this.state);
    if (!userData.hospital) emptyList.push('hospital');
    if (!userData.zipCode) emptyList.push('Zip Code');
    if (!userData.phoneNumber) emptyList.push('Phone Number');
    if (!userData.predxAmount) emptyList.push('PredX OD Limit');
    if (!userData.predxTime) emptyList.push('PredX Time Limit');
    return emptyList;
  }

  getMessage() {
    if (this.state.predx) {
      if (this.state.allZipCodes) {
        return `You will be notified for every ${this.state.predxAmount} overdoses in a span of ${this.state.predxTime} hours`;
      }
      return `You will be notified for every ${this.state.predxAmount} overdoses in a span of ${this.state.predxTime} hours in the following zip codes: ${this.state.zipCode}`;
    }
    return `The following fields are missing: ${this.state.missingList.join(', ')} Update your profile below if you would like PredX notifications.`;
  }

  getPredx() {
    if (this.state.predx) {
      const reports = this.props.reports;
      console.log(reports);

      const d = new Date();
      d.setHours(d.getHours() - this.state.predxTime);


      if (this.state.allZipCodes) {
        var filteredReports = reports.filter(el => Date.parse(el.createdAt) > d);
        var amount = filteredReports.length;
        return `There have been ${amount} overdoses over the last ${this.state.predxTime} hours in all zip codes.`;
      }
      filteredReports = reports.filter(function (el) {
        return el.createdAt > d && el.patientInformation.zipCode === this.state.zipCode;
      });
      amount = filteredReports.length;

      return `There have been ${amount} overdoses over the last ${this.state.predxTime} hours in the following zip codes: ${this.state.zipCode}`;
    }
  }

  render() {
    return (
      <div className="container-fluid status--card">
        <div className="row justify-content-md-left">
          <div className="col-12 card--title">
            <div className="status--card_inner">
              <div className="status--card_inner-text">
                <h4><strong>PredX Notification Settings</strong></h4>
                {this.getPredx()}
                {' '}
                <br />
                {this.getMessage()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  user: state.user.user,
  reports: loadReportsSelector(state.reports.reports, state.search.textFilter),
});

// ==========================================================
// CONNECT
// ----------------------------------------------------------

export default connect(
  mapStateToProps,
  null,
)(NotificationSection);
