import { analyticsActions } from './actions';

const analyticsDefaultState = {
  reports: [],
};

export function analyticsReducer(state = analyticsDefaultState, { payload, type }) {
  switch (type) {
    case analyticsActions.SET_PATIENT_DATA:
      return {
        ...state,
        patientData: payload.patientData,
      };
    case analyticsActions.SET_PATIENT_HOSPITAL:
      return {
        ...state,
        patientHospital: payload.patientHospital,
      };
    case analyticsActions.SET_PATIENT_INTENT:
      return {
        ...state,
        patientIntent: payload.patientIntent,
      };
    case analyticsActions.SET_PATIENT_NALOXONE_ADMINISTERED:
      return {
        ...state,
        naloxoneAdministered: payload.naloxoneAdministered,
      };
    case analyticsActions.SET_PATIENT_PULSE:
      return {
        ...state,
        patientPulse: payload.patientPulse,
      };
    case analyticsActions.SET_PATIENT_DISPOSITION:
      return {
        ...state,
        patientDisposition: payload.patientDisposition,
      };
    case analyticsActions.SET_PATIENT_RACE:
      return {
        ...state,
        patientRace: payload.patientRace,
      };
    case analyticsActions.SET_PATIENT_CAUSEOFOVERDOSE:
      return {
        ...state,
        patientCauseOfOverdose: payload.patientCauseOfOverdose,
      };
    case analyticsActions.SET_PATIENT_NALOXONE_ADMINISTERED_ELSE:
      return {
        ...state,
        naloxoneAdministeredElse: payload.naloxoneAdministeredElse,
      };
    case analyticsActions.SET_PATIENT_HUNGER:
      return {
        ...state,
        patientHunger: payload.patientHunger,
      };
    case analyticsActions.SET_PATIENT_HOUSING:
      return {
        ...state,
        patientHousing: payload.patientHousing,
      };
    case analyticsActions.SET_PATIENT_TRANSPORTATION:
      return {
        ...state,
        patientTransportation: payload.patientTransportation,
      };
    case analyticsActions.SET_HOSPITAL_SELECT:
      return {
        ...state,
        hospitalSelect: payload.hospital,
      };
    default:
      return state;
  }
}
