import React, { Component } from "react";
import Responsive from "react-responsive";
import axios from "axios";
import { Button } from "mdbreact";
import { connect } from "react-redux";
import moment from "moment";

import Footer from "../../components/app-footer";
import AppSidebar from "../../components/app-sidebar";
import AppHeader from "../../components/app-header";
import NotificationSection from "../../components/notification-section";
import NotificationTable from "../../components/notification-table";

import "./update-profile-page.scss";

const now = moment();

const Desktop = props => <Responsive {...props} minWidth={1500} />;
const Laptop = props => <Responsive {...props} maxWidth={1499} />;

class UpdateProfilePage extends Component {
  constructor(props) {
    super(props);
    let userData = this.props.user.user_metadata;
    if (!userData) {
      userData = {};
    }
    this.state = {
      user: {
        name: userData.name,
        hospital: "St. Luke's Cornwall Hospital",
        zipCode: userData.zipCode,
        phoneNumber: userData.phoneNumber,
        predxTime: userData.predxTime,
        predxAmount: userData.predxAmount,
        allZipCodes: false
      },
      missingValues: [],
      incorrectValues: []
    };
  }

  onChange = e => {
    const state = this.state;
    state.user[e.target.name] = e.target.value;
    this.setState(state);
    console.log(this.state);
  };

  changeCheckBox = e => {
    const state = this.state;
    if (state.user[e.target.name] === true) {
      state.user[e.target.name] = false;
    } else {
      state.user[e.target.name] = true;
    }
    this.setState(state);
    console.log(this.state);
  };

  checkValues = () => {
    const missingValues = [];
    if (!this.state.user.name) {
      missingValues.push("Name");
    }
    if (!this.state.user.hospital) {
      missingValues.push("hospital");
    }
    if (!this.state.user.zipCode) {
      missingValues.push("zipCode");
    }
    if (!this.state.user.phoneNumber) {
      missingValues.push("phoneNumber");
    }
    if (!this.state.user.predxTime) {
      missingValues.push("predxTime");
    }
    if (!this.state.user.predxAmount) {
      missingValues.push("predxAmount");
    }
    return missingValues;
  };

  verifyValues = () => {
    const incorrectValues = [];
    const user = this.state.user;
    // Zip Code
    if (!user.allZipCodes) {
      if (user.zipCode.includes(",")) {
        try {
        } catch (e) {
          incorrectValues.push("zipCode");
        }
      } else if (user.zipCode.length !== 5) incorrectValues.push("zipCode");
    }
    // Phone Number
    if (user.phoneNumber.length < 10) incorrectValues.push("phoneNumber");
    if (!user.phoneNumber.includes("+")) {
      const state = this.state;
      state.user.phoneNumber = `+1${user.phoneNumber}`;
      this.setState(state);
    }
    // predxTime
    if (isNaN(user.predxTime)) incorrectValues.push("predxTime");
    if (isNaN(user.predxAmount)) incorrectValues.push("predxAmount");
    return incorrectValues;
  };

  onSubmit = e => {
    const serverUrl = process.env.REACT_APP_API_URL;
    const clientUrl = process.env.REACT_APP_CLIENT_URL;
    const missingValues = this.checkValues();
    if (!missingValues.length) {
      const incorrectValues = this.verifyValues();
      if (!incorrectValues.length) {
        axios.defaults.headers.common.Authorization = localStorage.getItem(
          "accessToken"
        );
        e.preventDefault();
        axios
          .post(`${serverUrl}api/user/updateUser`, this.state.user)
          .then(result => {
            this.setState({ confirmSubmit: true });
            console.log(result);
            window.location.replace(`${clientUrl}?update=true`);
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.setState({ message: "Data upload failed." });
              console.log(error);
            }
          });
      } else {
        this.setState({ incorrectValues });
      }
    } else {
      this.setState({ missingValues });
    }
  };

  pageContent() {
    let userData = this.props.user.user_metadata;
    if (!userData) {
      userData = {};
    }
    return (
      <div>
        <div className="row justify-content-md-center">
          <div className="col-6 col--top_margin">
            <label className="grey-text">Name:</label>
            <input
              type="text"
              name="name"
              defaultValue={userData.name}
              onChange={this.onChange}
              className="form-control w-100"
              required
            />
          </div>
          <div className="col-6 col--top_margin">
            <label value="St. Luke's Cornwall Hospital" className="grey-text">
              Hospital
            </label>
            <select
              name="hospital"
              onChange={this.onChange}
              className="form-control w-100"
              required
            >
              <option value="St. Luke's Cornwall Hospital">
                St. Luke's Cornwall Hospital
              </option>
              <option value="Orange Regional Medical Center">
                Orange Regional Medical Center
              </option>
              <option value="Catskill Regional Medical Center">
                Catskill Regional Medical Center
              </option>
              <option value="Ellenville Hospital">Ellenville Hospital</option>
            </select>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-6 col--top_margin">
            <label className="grey-text">Zip Code(s)</label>
            <input
              className="all-zipcodes"
              name="allZipCodes"
              onChange={this.changeCheckBox}
              type="checkbox"
            />
            <label className="grey-text"> Track all zip codes</label>
            <input
              type="text"
              name="zipCode"
              defaultValue={userData.zipCode}
              onChange={this.onChange}
              className="form-control w-100"
              required
            />
          </div>
          <div className="col-6 col--top_margin">
            <label className="grey-text">Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              defaultValue={userData.phoneNumber}
              onChange={this.onChange}
              className="form-control w-100"
              required
            />
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-6 col--top_margin">
            <label className="grey-text">
              I want to be notified if this many overdoses occur:
            </label>
            <input
              type="text"
              name="predxAmount"
              defaultValue={userData.predxAmount}
              onChange={this.onChange}
              className="form-control w-100"
              required
            />
          </div>
          <div className="col-6 col--top_margin">
            <label className="grey-text">
              Over the span of this many hours:
            </label>
            <input
              type="text"
              name="predxTime"
              defaultValue={userData.predxTime}
              onChange={this.onChange}
              className="form-control w-100"
              required
            />
          </div>
        </div>
        <div className="row justify-content-md-right update-submit-button">
          <div className="col-6">
            <Button
              color="elegant"
              size="md"
              style={{ marginLeft: "0" }}
              onClick={this.onSubmit}
            >
              Submit
            </Button>
          </div>
          <div className="col-6" />
        </div>
      </div>
    );
  }

  render() {
    let appData = this.props.user.app_metadata;
    if (!appData) appData = {};
    return (
      <div>
        <Desktop>
          <AppSidebar open={false} activeItem="home" />
          <AppHeader activeItem="account" showSidebarMargins />
          <div className="main-container">
            {appData.admin ? (
              <div>
                <div className="row justify-content-md-left">
                  <div className="col-12 card--title">
                    <h2 className="w-500" style={{ marginTop: "1em" }}>
                      Notification Data Overview
                      <span className="last-updated-subtitle w-200">
                        {now.format("dddd, MMMM Do YYYY, h:mm:ss a")}
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="row justify-content-md-left wrapper--custom card--wrapper">
                  <div className="col-12" style={{ marginTop: "1em" }}>
                    <NotificationTable />
                  </div>
                </div>
              </div>
            ) : null}
            <NotificationSection />
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.state.missingValues.length ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    The following required fields are missing:{" "}
                    {this.state.missingValues.join(", ")}
                  </div>
                </div>
              ) : null}
              {this.state.incorrectValues.indexOf("zipCode") !== -1 ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    Please input a valid 5-digit zip code or a comma seperated
                    list of zip codes.
                  </div>
                </div>
              ) : null}
              {this.state.incorrectValues.indexOf("phoneNumber") !== -1 ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    Please input a valid phone number.
                  </div>
                </div>
              ) : null}
              {this.state.incorrectValues.indexOf("predxAmount") !== -1 ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    Please input a numeric value for overdose amount.
                  </div>
                </div>
              ) : null}
              {this.state.incorrectValues.indexOf("predxTime") !== -1 ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    Please input a numeric value for overdose time interval.
                  </div>
                </div>
              ) : null}
              <div className="row justify-content-md-left">
                <div className="col-12 card--title">
                  <h2 className="w-500">
                    Predx Account Settings
                    <span className="last-updated-subtitle w-200" />
                  </h2>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12" style={{ marginTop: "1em" }}>
                  {this.pageContent()}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Desktop>
        <Laptop>
          <AppSidebar open activeItem="home" />
          <AppHeader activeItem="account" showSidebarMargins={false} />
          <div className="main-container no--sidebar">
            {appData.admin ? (
              <div>
                <div className="row justify-content-md-left">
                  <div className="col-12 card--title">
                    <h4 className="w-500" style={{ marginTop: "1em" }}>
                      Notification Data Overview
                      <span className="last-updated-subtitle w-200">
                        {now.format("dddd, MMMM Do YYYY, h:mm:ss a")}
                      </span>
                    </h4>
                  </div>
                </div>
                <div className="row justify-content-md-left wrapper--custom card--wrapper">
                  <div className="col-12" style={{ marginTop: "1em" }}>
                    <NotificationTable />
                  </div>
                </div>
              </div>
            ) : null}
            <NotificationSection />
            <div className="container-fluid" style={{ paddingTop: "2em" }}>
              {this.state.missingValues.length ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    The following required fields are missing:{" "}
                    {this.state.missingValues.join(", ")}
                  </div>
                </div>
              ) : null}
              {this.state.incorrectValues.indexOf("zipCode") !== -1 ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    Please input a valid 5-digit zip code or a comma seperated
                    list of zip codes.
                  </div>
                </div>
              ) : null}
              {this.state.incorrectValues.indexOf("phoneNumber") !== -1 ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    Please input a valid phone number.
                  </div>
                </div>
              ) : null}
              {this.state.incorrectValues.indexOf("predxAmount") !== -1 ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    Please input a numeric value for overdose amount.
                  </div>
                </div>
              ) : null}
              {this.state.incorrectValues.indexOf("predxTime") !== -1 ? (
                <div className="col-12 card--title">
                  <div className="alert alert-danger" role="alert">
                    Please input a numeric value for overdose time interval.
                  </div>
                </div>
              ) : null}
              <div className="row justify-content-md-left">
                <div className="col-12 card--title">
                  <h4 className="w-500">
                    Predx Account Settings
                    <span className="last-updated-subtitle w-200" />
                  </h4>
                </div>
              </div>
              <div className="row justify-content-md-left wrapper--custom card--wrapper">
                <div className="col-12" style={{ marginTop: "1em" }}>
                  {this.pageContent()}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Laptop>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user
});

export default connect(mapStateToProps, null)(UpdateProfilePage);
